import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RequireAdmin = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check user role from your authentication context or state
    const userRole = localStorage.getItem('userRole'); // Assuming user role is stored in localStorage
    if (userRole !== 'Admin') {
      // Redirect to a different route if the user is not an admin
      navigate('/'); // Redirect to the home page or an error page
    }
  }, [navigate]);

  return <>{children}</>;
};

export default RequireAdmin;
