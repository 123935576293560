import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import api from '../../../axiosApi/api';

import CreateProjectModal from '../../Modals/CreateProjectModal/CreateProjectModal';
import FiltersModal from '../../Modals/FiltersModal/FiltersModal';
import AdminProjectsUtilsBar from '../../UtilsBar/ProjectsUtilsBar/AdminProjectsUtilsBar';

import "./../Dashboard.css";
import "./ProjectsDashboard.css";

import naIcon from "../../../Images/naNew.png";
import optionIcon from "../../../Images/menu-vertical.png";
import deleteIcon from "../../../Images/delete.png";
import ErrorIcon from "../../../Images/error-icon.png"


function AdminProjectsDashboard() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [openCreationModal, setOpenCreationModal] = useState(false);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [showOptions, setShowOptions] = useState({});
  const [error, setError] = useState(false)


  useEffect(() => {
    fetchProjects();
    return () => {};
  }, []);


  const fetchProjects = async () => {
    try {
      const res = await api.get('/api/projects', {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      });
      setError(false);
      setData(res.data.projects);
      setFilteredData(res.data.projects);
      setProjectsLoading(false);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError(true);
      setProjectsLoading(false);
    }
  };

  
  const toggleOptions = (index) => {
    setShowOptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const deleteProject = async (projectId) => {
    try {
      await api.delete(`/api/projects/${projectId}`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      });
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const applyFilters = (setFromDate, setToDate, setModalities, setTypes) => {
    const filteredProjects = data.filter((project) => {
      const creationDate = project['creation_date']
      const [day, month, year] = creationDate.split('-');
      const formatedCreationDate = new Date(`20${year}`, month - 1, day);

      const allModalitiesAreUnchecked = Object.values(setModalities).every(value => value === false);
      const allTypesAreUnchecked = Object.values(setTypes).every(value => value === false);

      return (
        (setFromDate===null || formatedCreationDate > setFromDate) &&
        (setToDate===null || formatedCreationDate < setToDate) &&
        (allModalitiesAreUnchecked || setModalities[project.modality]===true) &&
        (allTypesAreUnchecked || setTypes[project.type]===true)
      ); 
    })

    // resetSorting();
    setFilteredData(filteredProjects);
  };

  const submitProject = (projectName, projectDescription, preannotatedSetup, selectedModality,  
    selectedTemplate, labels, selectedDataset, selectedInstances, selectedChunks) => {
      return new Promise((resolve, reject) => {
        const data = {
          'project_name': projectName,
          'project_description': projectDescription,
          'preannotated_setup': preannotatedSetup,
          'selected_modality': selectedModality,
          'selected_template': selectedTemplate,
          'labels': labels,
          'selected_dataset': selectedDataset,
          'selected_instances': selectedInstances,
          'selected_chunks': selectedChunks
        };

        api.post('/api/projects', data, {
          headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
          },
        }).then((res) => {
          fetchProjects();
          resolve({ success: true });
        }).catch((error) => {
          reject({ success: false, error: error.message });
        });
      });
    };


    function configureStatusColor(status) {
      let backgroundColor;
  
      switch (status) {
          case 'Complete':
              backgroundColor = 'lightblue';
              break;
          case 'Paused':
              backgroundColor = 'purple';
              break;
          case 'Active':
              backgroundColor = 'lightgreen';
              break;
          default:
              backgroundColor = 'gray'; // Default color if status is unknown
      }

      return {
        backgroundColor: backgroundColor,
      };
    }

  return (
    <>
      <CreateProjectModal open={openCreationModal} onClose={() => setOpenCreationModal(false)} submitProject={submitProject} />
      <FiltersModal open={openFiltersModal} onClose={() => setOpenFiltersModal(false)} applyFilters={applyFilters} />
      <section className="dashboard projects-dashboard">
        <h2>Available Projects</h2>
        <AdminProjectsUtilsBar
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          openFiltersModal={() => setOpenFiltersModal(true)}
          openCreationModal={() => setOpenCreationModal(true)}
        />
        <div className="project-cards-container">
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) : projectsLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : filteredData.length > 0 ? (
            <div className="project-cards-grid">
              {filteredData.map((project, index) => (
                <div key={project.projectId} className="project-card">
                  <div className="card-options-container">
                    <div className="card-options-icon" onClick={() => toggleOptions(index)}>
                      <img src={optionIcon} alt="Options" />
                    </div>
                    {showOptions[index] && (
                      <ul className="card-options-list">
                        <li className="list-item" onClick={() => deleteProject(project.projectId)}>
                          <img src={deleteIcon} className="list-item-icon" alt="Delete" />
                          <span className="list-item-text">Delete Project</span>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="project-details">
                    <div className="project-title-and-status">
                      <div className="project-card-title">{project.projectName}</div>
                      <div className="project-status" style={configureStatusColor(project.projectStatus)} title={project.projectStatus}/>
                    </div>
                    <div className="project-card-modality-and-type">
                      <span>{project.projectModality["name"]}</span> - <span>{project.projectTaskType["name"]}</span>
                    </div>
                    <span className="project-card-date">{project.creationDate}</span>
                    <span className="project-card-description">{project.projectDescription}</span>
                  </div>
                  <button className="project-card-btn">
                    <Link
                      className="btn-link"
                      to={`${currentPath}/${project.projectId}`}
                      state={{
                        taskType: project.projectTaskType.name,
                        taskView: project.projectTaskType.view,
                        annotationType: project.projectTaskType.annotation_type,
                        projectModality: project.projectModality["name"],
                        projectLabels: project.projectLabels,
                        projectStatus: project.projectStatus
                      }}
                    >
                      Open <span>&gt;</span>
                    </Link>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="message-container">
              <img src={naIcon} className="message-icon" alt="Not Available" />
              <span className="message-text-main">Not available projects</span>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AdminProjectsDashboard;
