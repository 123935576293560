import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import api from '../../../axiosApi/api';
import JSZip from 'jszip';

import AdminProjectInstancesUtilsBar from '../../UtilsBar/ProjectInstancesUtilsBar/AdminProjectInstancesUtilsBar';
import DataChunkAnnotatorsModal from "../../Modals/AnnotatorsModal/DataChunkAnnotatorsModal/DataChunkAnnotatorsModal";
import ProjectAnnotatorsModal from "../../Modals/AnnotatorsModal/ProjectAnnotatorsModal/ProjectAnnotatorsModal";
import DataChunkStatisticsModal from "../../Modals/StatisticsModal/DataChunkStatisticsModal/DataChunkStatisticsModal";
import InstanceStatisticsModal from "../../Modals/StatisticsModal/InstanceStatisticsModal/InstanceStatisticsModal";
import ProjectStatusModal from "../../Modals/ProjectStatusModal/ProjectStatusModal";

import "./AdminProjectInstancesDashboard.css";
import './../Dashboard.css';

import naIcon from "../../../Images/naNew.png";
import statsIcon from "../../../Images/stats.png";
import RightArrowIcon from "../../../Images/right-arrow.png";
import DownArrowIcon from "../../../Images/down-arrow.png";
import AnnotatorsIcon from "../../../Images/annotators.png";
import DownloadIcon from "../../../Images/download-icon.png";
import ErrorIcon from "../../../Images/error-icon.png"


function AdminProjectInstancesDashboard() {
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    taskType: taskTypeParam, 
    taskView: taskViewParam, 
    annotationType: annotationTypeParam, 
    projectModality: projectModalityParam, 
    projectLabels: projectLabelsParam, 
    projectStatus: projectStatusParam
  } = location.state || {};
  const { projectId } = useParams();

  const [projectDetails, setProjectDetails] = useState({
    taskType: taskTypeParam,
    taskView: taskViewParam,
    annotationType: annotationTypeParam,
    projectModality: projectModalityParam,
    projectLabels: projectLabelsParam,
    projectStatus: projectStatusParam
  });

  const [dataChunks, setDataChunks] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);

  const [openModals, setOpenModals] = useState({
    filters: false,
    projectAssignments: false,
    dataChunkAssignments: false,
    dataChunkStatistics: false,
    instanceStatistics: false,
    projectStatus: false,
  });
  
  const [selectedDataChunk, setSelectedDataChunk] = useState(null);
  const [selectedDataChunkIndex, setSelectedDataChunkIndex] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);

  const [loadingDataChunks, setLoadingDataChunks] = useState(true);
  const [error, setError] = useState(false);


  const fetchInstancesPerDataChunk = async () => {
    try {
      const res = await api.get(`/api/projects/${projectId}/data-chunks`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: { 'include-instances-metadata': "true" }
      });
      setError(false);
      setDataChunks(res.data.dataChunks);
      setExpandedStates(new Array(res.data.dataChunks.length).fill(false));
    } catch (error) {
      setError(true);
      console.error("Error fetching instances:", error);
    } finally {
      setLoadingDataChunks(false);
    }
  };


  const fetchProjectStatus = async () => {
    try {
      const res = await api.get(`/api/projects/${projectId}`, {
        headers: {"Authorization": 'Bearer ' + localStorage.getItem('jwtToken')}
      });
      setError(false);
      const updatedProjectStatus = res.data.project.projectStatus
      setProjectDetails(prevState => ({
        ...prevState,
        projectStatus: updatedProjectStatus
      }));
    } catch (error) {
      setError(true);
      console.error("Error fetching instances:", error);
    } 
  }


  const toggleExpanded = (index) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };


  const updateAssignments = (pendingActions) => {
    return new Promise((resolve, reject) => {
      api.put('/api/users', pendingActions, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        },
      }).then((res) => {
        resolve({ success: true });
      }).catch((error) => {
        console.log(error);
        reject({ success: false, error: error.message });
      });
    });
  };


  const exportProjectAnnotations = async () => {
    try{
      const res = await api.get(`/api/projects/${projectId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.data);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`Project_${projectId} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  const exportDataChunkAnnotations = async (dataChunkId) => {
    try{
      const res = await api.get(`/api/data-chunks/${dataChunkId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.dataChunks);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`DataChunk_${selectedDataChunkIndex} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  const exportInstanceAnnotations = async (dataChunkId, instanceId) => {
    try{
      const res = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/annotations`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
      })
      
      const jsonString = JSON.stringify(res.data);
      const jsonBlob = new Blob([jsonString], { type: 'application/json' });

      const zip = new JSZip();
      zip.file(`DataChunk_${selectedDataChunkIndex} Instance_${selectedInstance['name']} annotations.json`, jsonBlob);

      try {
        const zipContent = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipContent);
        link.download = 'annotations.zip';
        link.click();
      } catch (error) {
        console.error("Error generating zip file:", error);
      }

    }catch (error) {
      console.error("Error fetching instance annotations:", error);
    }
  }


  useEffect(() => {
    fetchInstancesPerDataChunk();
    return () => {};
  }, [projectId]);


  return (
    <> 
      {
        (openModals.projectAssignments) && (
          <ProjectAnnotatorsModal 
            selectedProjectId = {projectId}
            dataChunks = {dataChunks.map(item => item.id)}
            updateAssignments={updateAssignments}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              projectAssignments: false
            }))}    
          />
        )
      }
      {
        (openModals.dataChunkAssignments) && (
          <DataChunkAnnotatorsModal 
            selectedProjectId = {projectId}
            selectedDataChunkId = {selectedDataChunk.id}
            updateAssignments={updateAssignments}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              dataChunkAssignments: false
            }))} 
          />
        )
      }
      {
        (openModals.dataChunkStatistics) && (
          <DataChunkStatisticsModal 
            taskType = {projectDetails.taskType}
            dataChunkId = {selectedDataChunk.id}
            dataChunkNumber = {selectedDataChunkIndex}
            dataChunkInstances = {selectedDataChunk.instances}
            taskView = {projectDetails.taskView}
            annotationType = {projectDetails.annotationType}
            projectModality = {projectDetails.projectModality}
            projectLabels = {projectDetails.projectLabels}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              dataChunkStatistics: false
            }))} 
          />
        )
      }
      {
        (openModals.instanceStatistics) && (
          <InstanceStatisticsModal
            taskType = {projectDetails.taskType} 
            dataChunkId = {selectedDataChunk.id}
            instanceId = {selectedInstance.id}
            instanceName = {selectedInstance.name}
            instanceRelativePath = {selectedInstance.relativePath}
            taskView = {projectDetails.taskView}
            annotationType = {projectDetails.annotationType}
            projectModality = {projectDetails.projectModality}
            projectLabels = {projectDetails.projectLabels}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              instanceStatistics: false
            }))} 
          />
        )
      }
      {
        (openModals.projectStatus) && (
          <ProjectStatusModal 
            projectId = {projectId}
            projectStatus = {projectDetails.projectStatus}
            fetchProjectStatus = {() => fetchProjectStatus()}
            onClose={() => setOpenModals(prevState => ({
              ...prevState,
              projectStatus: false
            }))} 
          />
        )
      }
      
      {/* Main Dashboard Content */}
      <section className="dashboard project-instances-dashboard">
        <h2>Project Instances</h2>
        <AdminProjectInstancesUtilsBar 
          filteredData={dataChunks}
          setFilteredData={setDataChunks}
          projectStatus = {projectDetails.projectStatus}
          setOpenFiltersModal={() => setOpenModals(prevState => ({
            ...prevState,
            filters: true
          }))} 
          setOpenAssignmentsModal={() => setOpenModals(prevState => ({
            ...prevState,
            projectAssignments: true
          }))}
          setOpenProjectStatusModal={() => setOpenModals(prevState => ({
            ...prevState,
            projectStatus: true
          }))}
          exportProjectAnnotations={() => exportProjectAnnotations(projectId)}
        />
        <div className="project-instances-container">
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) : loadingDataChunks ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : dataChunks.length === 0 ? (
            <div className="message-container">
              <img src={naIcon} className="message-icon" alt="Not Available" />
              <p className="message-text-main">No available data chunks.</p>
            </div>
          ) : (
            <div className="project-instances-list">
              {dataChunks.map((dataChunk, dataChunkIndex) => (
                <div className="data-chunk-sublist" key={dataChunk.id}>
                  <div className="data-chunk-card">
                    <div className="data-chunk-card-title"> Data Chunk {dataChunkIndex + 1} </div>
                    <div className="data-chunk-card-utils">
                      <button 
                        className="annotators-btn" 
                        title="Assign Annotators"
                        onClick={() => {
                          setSelectedDataChunk(dataChunk);
                          setOpenModals(prevState => ({
                            ...prevState,
                            dataChunkAssignments: true
                          }))
                        }}
                      >
                        <img src={AnnotatorsIcon} alt="Assign Annotators" />
                      </button>
                      <button
                        className="stats-btn" 
                        title="Statistics"
                        onClick={() => {
                          setSelectedDataChunk(dataChunk);
                          setSelectedDataChunkIndex(dataChunkIndex);
                          setOpenModals(prevState => ({
                            ...prevState,
                            dataChunkStatistics: true
                          }))             
                        }}
                      >
                        <img src={statsIcon} alt="Statistics"/>
                      </button>
                      <button
                        className="stats-btn" 
                        title="Export Annotations"
                        onClick={() => {
                          setSelectedDataChunk(dataChunk);
                          setSelectedDataChunkIndex(dataChunkIndex);
                          exportDataChunkAnnotations(dataChunk.id);                          
                        }}
                      >
                        <img src={DownloadIcon} alt="Export Annotations"/>
                      </button>
                      <div className="expand-btn" onClick={() => toggleExpanded(dataChunkIndex)}>
                        <img src={expandedStates[dataChunkIndex] ? DownArrowIcon : RightArrowIcon} alt="Expand" />
                      </div>
                    </div>
                  </div>
                  {expandedStates[dataChunkIndex] && (
                    <div className="instances-cards-header">
                      <div className="fields-section">
                        <span className="header-field">Name</span>
                        <span className="header-field">File Type</span>
                        <span className="header-field">File Size</span>
                      </div>
                      <div className="empty-section"></div>
                    </div>
                  )}
                  {expandedStates[dataChunkIndex] && (
                    dataChunk.instances.map((instance, instanceIndex) => (
                      <div className="instance-card" key={instance.id}>
                        <div className="item-fields-section">
                          <span className="item-field instance-card-title">{instance.name}</span>
                          <span className="item-field">{instance.fileType}</span>
                          <span className="item-field">{instance.fileSize}</span>
                        </div>
                        <div className="item-utils-section">
                          <button className="view-btn">
                            <Link
                              className="btn-link"
                              to={`${currentPath}/data-chunks/${dataChunk.id}/instances/${instance.id}`}
                              state={{
                                projectId: projectId,
                                dataChunkId: dataChunk.id,
                                instanceId: instance.id,
                                instanceParentDatasetName: instance.parentDataset.name,
                                instanceName: instance.name,
                                instanceFileType: instance.fileType,
                                instanceFileSize: instance.fileSize,
                                instanceRelativePath: instance.relativePath
                              }}
                            >
                              View <span>&gt;</span>
                            </Link>
                          </button>
                          <button
                            className="stats-btn" 
                            title="Statistics"
                            onClick={() => {
                              setSelectedInstance(instance);
                              setSelectedDataChunk(dataChunk);
                              setSelectedDataChunkIndex(dataChunkIndex);
                              setOpenModals(prevState => ({
                                ...prevState,
                                instanceStatistics: true
                              }))
                            }}
                          >
                            <img src={statsIcon} alt="Statistics"/>
                          </button>
                          <button
                            className="stats-btn" 
                            title="Export Annotations"
                            onClick={() => {
                              setSelectedDataChunk(dataChunk);
                              setSelectedDataChunkIndex(dataChunkIndex);
                              setSelectedInstance(instance);
                              exportInstanceAnnotations(dataChunk.id, instance.id);                    
                            }}
                          >
                            <img src={DownloadIcon} alt="Export Annotations"/>
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AdminProjectInstancesDashboard;
