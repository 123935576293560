import React, { useState } from "react";
import DatePicker from 'react-date-picker';
import xIcon from "../../../Images/x-icon.png";
import "../Modals.css";
import "./FiltersModal.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay'

const FiltersModal = ({ open, onClose, applyFilters }) => {
  const [fromDateValue, setFromDateValue] = useState(null);
  const [toDateValue, setToDateValue] = useState(null);
  const [selectedModalities, setSelectedModalities] = useState({});
  const [selectedTypes, setSelectedTypes] = useState({});

  const handleApplyFilters = () => {
    applyFilters(fromDateValue, toDateValue, selectedModalities, selectedTypes);
    onClose();
  };

  const clearFilters = () => {
    setFromDateValue(null);
    setToDateValue(null);
    setSelectedModalities({});
    setSelectedTypes({});
  };

  const onCloseClick = () => {
    clearFilters();
    onClose();
  };

  if (!open) return null;

  return (
    <>
      <BackgroundOverlay></BackgroundOverlay>
      <div className="filters-modal">
        <div className="filters-modal-header">
          <h3>Filter Projects</h3>
          <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
        </div>

        <div className="filters-modal-body">
          <div className="filters-grid-container">
            <div className="filters-grid-item first-row">
              <div className="single-filter-header">
                <h6>Creation Date</h6>
                <button className="clear-filters-button" onClick={() => setFromDateValue(null)}>Clear</button>
              </div>
              <div className="date-filters-container">
                <div className="date-filter">
                  <div>From:</div>
                  <DatePicker className="date-picker" onChange={setFromDateValue} value={fromDateValue} />
                </div>
                <div className="date-filter">
                  <div>To:</div>
                  <DatePicker className="date-picker" onChange={setToDateValue} value={toDateValue} />
                </div>
              </div>
            </div>

            <div className="filters-grid-item second-row">
              <div className="single-filter-header">
                <h6>Modality</h6>
                <button className="clear-filters-button" onClick={() => setSelectedModalities({})}>Clear</button>
              </div>
              <div className="checkbox-list-container">
                {['Image', 'Audio', 'Text'].map(modality => (
                  <label key={modality} className="checkbox-list-entry">
                    <span className="checkbox-label">{modality}</span>
                    <input
                      type="checkbox"
                      checked={selectedModalities[modality] || false}
                      onChange={() => setSelectedModalities(prevState => ({ ...prevState, [modality]: !prevState[modality] }))}
                    />
                    <span className="check-mark"></span>
                  </label>
                ))}
              </div>
            </div>

            <div className="filters-grid-item">
              <div className="single-filter-header">
                <h6>Task Type</h6>
                <button className="clear-filters-button" onClick={() => setSelectedTypes({})}>Clear</button>
              </div>
              <div className="checkbox-list-container">
                {['RectangleLabels', 'PolygonLabels', 'SoundEventDetection', 'EmotionDetection'].map(type => (
                  <label key={type} className="checkbox-list-entry">
                    <span className="checkbox-label">{type}</span>
                    <input
                      type="checkbox"
                      checked={selectedTypes[type] || false}
                      onChange={() => setSelectedTypes(prevState => ({ ...prevState, [type]: !prevState[type] }))}
                    />
                    <span className="check-mark"></span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="filters-modal-footer">
          <button className="util-footer-btn" onClick={handleApplyFilters}> Apply Filters </button>
          <button className="clear-filters-button" onClick={clearFilters}> Clear All</button>
        </div>
      </div>
    </>
  );
};

export default FiltersModal;
