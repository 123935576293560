import React from "react";
import { Link } from "react-router-dom";

import projectIcon from "../../Images/project.png";
import coloredProjectIcon from "../../Images/project-colored.png";
import datasetIcon from "../../Images/dataset.png";
import coloredDatasetIcon from "../../Images/dataset-colored.png";
import annotatorIcon from "../../Images/annotator.png";
import coloredAnnotatorIcon from "../../Images/annotator-colored.png";
import utilitiesIcon from "../../Images/utilities.png";
import coloredUtilitiesIcon from "../../Images/utilities-colored.png";


import "./DashboardSideBar.css";

function DashboardSideBar({ selectedDashboard, onDashboardChange }) {

  const renderSidebarItem = (itemName, route, icon, coloredIcon) => (
    <li key={itemName}>
      <Link
        to={route}
        className={`side-bar-item ${selectedDashboard === itemName ? "selected" : ""}`}
        title={itemName}
        onClick={() => onDashboardChange(itemName)}
      >
        <img src={selectedDashboard === itemName ? coloredIcon : icon} alt={`${itemName} Icon`} />
        {/* <span className="bar-item-name">{itemName}</span> */}
      </Link>
    </li>
  );

  return (
    <div className="dashboard-side-bar">
      <ul>
        {renderSidebarItem("projects", "/admin/dashboard/projects", projectIcon, coloredProjectIcon)}
        {renderSidebarItem("datasets", "/admin/dashboard/datasets", datasetIcon, coloredDatasetIcon)}
        {renderSidebarItem("annotators", "/admin/dashboard/annotators", annotatorIcon, coloredAnnotatorIcon)}
        {/* {renderSidebarItem("utilities", "/admin/dashboard/utilities", utilitiesIcon, coloredUtilitiesIcon)} */}
      </ul>
    </div>
  );
}

export default DashboardSideBar;
