import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { initializeLabelStudio } from "./LabelStudioConfig";

import api from '../../../axiosApi/api';

import "./../Dashboard.css"
import "./InstanceDashboard.css"

import ErrorIcon from "../../../Images/error-icon.png";

function InstanceDashboard() {

  const location = useLocation();  
  const { projectId, dataChunkId, instanceId, instanceParentDatasetName, instanceName, 
          instanceFileType, instanceFileSize, instanceRelativePath } = location.state;
  const [instanceLoading, setInstanceLoading] = useState(false)
  const [projectTaskType, setProjectTaskType] = useState()
  const [error, setError] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      setInstanceLoading(true);

      try {
        // Fetch project details
        const projectDetailsRes = await api.get(`/api/projects/${projectId}`, {
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
          },
          params: {
            'include-modality-metadata': "true",
            "include-task-type-metadata": "true"
          }
        });
        setError(false);

        const fetchedProject = projectDetailsRes.data.project;
        const projectModality = fetchedProject['projectModality'];
        const projectTaskType = fetchedProject['projectTaskType'];
        const projectLabels = fetchedProject['projectLabels'];
        setProjectTaskType(projectTaskType['name']);

        // Fetch available annotations
        const annotationsRes = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/annotations`, {
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
          }
        });
        const fetchedAnnotations = annotationsRes.data.annotations;

        await initializeLabelStudio(projectModality, 
                                    projectTaskType, 
                                    projectLabels, 
                                    fetchedAnnotations, 
                                    dataChunkId,
                                    instanceRelativePath);
      } catch (error) {
        setError(true);
        console.error("Error:", error);
      } finally {
        setInstanceLoading(false);
      }
    };
  
    fetchData();
    return () => {};
  }, [projectId, dataChunkId, instanceId, instanceRelativePath]);


  const configureStyle = () => {
    if (projectTaskType === "PolygonLabels"){
      import("./InstDashPolygonLabels.css");
      return("inst-dash-polygon-labels")
    } 
    else if (projectTaskType === "RectangleLabels"){
      import("./InstDashRectangleLabels.css");
      return("inst-dash-rectangle-labels")
    } 
    else if (projectTaskType === "ImageClassification"){
      import("./InstDashImageClassification.css");
      return("inst-dash-image-classification");
    } 
    else if (projectTaskType === "ImageCaptioning"){
      import("./InstDashImageCaptioning.css");
      return("inst-dash-image-captioning");
    } 
    else if (projectTaskType === "AudioCaptioning"){
      import("./InstDashAudioCaptioning.css");
      return("inst-dash-audio-captioning");
    } 
    else if (projectTaskType === "AudioClassification"){
      import("./InstDashAudioClassification.css");
      return("inst-dash-audio-classification");
    } 
    else if (projectTaskType === "SegmentsAudioClassification"){
      import("./InstDashSegmentsAudioClassification.css");
      return("inst-dash-segments-audio-classifiction");
    } 
    else if (projectTaskType === "SegmentsAudioCaptioning"){
      import("./InstDashSegmentsAudioCaptioning.css");
      return("inst-dash-segments-audio-captioning");
    } 
    else if (projectTaskType === "ValenceArousal"){
      import("./InstDashValenceArousal.css");
      return("inst-dash-valence-arousal");
    } 
    else if (projectTaskType === "AdversarialDetection"){
      import("./InstDashAdversarialDetection.css");
      return("inst-dash-adversarial-detection");
    } 
  }
  

  return (
    <>
      <section className="dashboard instance-dashboard">
        <h2>{instanceName}</h2>
        <div className="label-studio-container">
          {error ? (
            <div className="message-container">
                <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                <p className="message-text-main">An unexpected error occurred.</p> 
                <p className="message-text">Please try again later.</p>
            </div>
          ) : instanceLoading && (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) 
        }
        <div 
          id="label-studio" 
          className={configureStyle()}
          style={{ display: instanceLoading ? 'none' : 'block' }}
        />
        </div>
      </section>
    </>
  )

}

export default InstanceDashboard