import { React, useState, useEffect, Fragment } from "react";
import api from '../../../../../axiosApi/api';

import "./TemplateSelectionStage.css"


const TemplateSelectionStage = ({selectedModality, setSelectedModality, selectedTemplate, setSelectedTemplate}) => {

  const [availableModalities, setAvailableModalities] = useState([]);
  const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
  const [visibleTemplates, setVisibleTemplates] = useState([])

  useEffect(() => {
    // Fetch available modalities
    api.get('/api/modalities', {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      setAvailableModalities(res.data.modalities);
      if (selectedModality===undefined) setSelectedModality(res.data.modalities[0]);
    }).catch((error) => {
      console.log(error);
    });

    // Fetch available task types
    api.get('/api/task-types', {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
    }).then((res) => {
      const fetchedTaskTypes = res.data.task_types;
      const updatedTaskTypes = fetchedTaskTypes.map(taskType => ({
        ...taskType,
        imgPath: require(`../../../../../Images/template-images/${taskType["name"]}.png`)
      }));
      setAvailableTaskTypes(updatedTaskTypes);
    }).catch((error) => {
      console.log(error);
    });

  }, []);

  // Filter available templates based on selected modality
  useEffect(() => {    
    // Ensure selectedModality and availableTaskTypes are defined before accessing their properties
    if (selectedModality && availableTaskTypes) {
      setVisibleTemplates(availableTaskTypes.filter(taskType => taskType["modality"] === selectedModality["name"]));
    }
  }, [selectedModality, availableTaskTypes]);


  return (
    <div className="template-selection-container">
      <div className="modality-selection-section">
        <h5>Modality</h5>
        {availableModalities.map(modality  => (
          <button 
            key={modality._id} 
            className={`modality-btn ${selectedModality._id === modality._id ? 'selected' : ''}`}
            onClick={() => setSelectedModality(modality)}
          >{modality.name}</button>
        ))}
      </div>
      <div className="vertical-separator-section">
        <div className="vertical-separator"></div>
      </div>
      <div className="template-selection-section">
        <h5>Template</h5>
        <div className="template-cards-grid">
          {visibleTemplates.map((taskType) => (
            // Add a check to ensure taskType is not undefined before accessing its properties
            taskType && (
              <div 
                key={taskType._id} 
                className={`template-card ${selectedTemplate && selectedTemplate._id === taskType._id ? 'selected': ''}`}
                onClick={() => setSelectedTemplate(taskType)}
              >
                <div className="img-container">
                  <img src={taskType.imgPath} alt={taskType.description} className="template-card-image" />
                </div>
                <p className="template-card-title">{taskType.description}</p>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  )
}
export default TemplateSelectionStage