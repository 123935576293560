import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import logoIcon from "../../Images/logo.ico";
import userIcon from "../../Images/user.png";
import infoIcon from "../../Images/info-white.png";
import signoutIcon from "../../Images/signout.png";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [profDropdownIsVisible, setProfDropdownIsVisible] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const userRole = localStorage.getItem('userRole');
  const username = localStorage.getItem('username');

  const toggleProfileDropdown = () => {
    setProfDropdownIsVisible(!profDropdownIsVisible);
  };

  const handleNavLinkClick = (to) => {
    setActiveLink(to);
    navigate(to);
  };

  useEffect(() => {
    // Update the active link based on the current location
    const currentPath = location.pathname;
    setActiveLink(currentPath);
  }, [location]);

  return (
    <header>
      <img src={logoIcon} className="logo-icon" alt="Logo Icon" />
      <nav className="navigation-options">
        <div
          className={activeLink.includes("/dashboard") ? "active" : ""}
          onClick={() => handleNavLinkClick((userRole === 'Admin') ? "/admin/dashboard" : "/annotator/dashboard")}
        >
          Dashboard
        </div>
        <span className="navigation-separator">|</span>
        <div
          className={activeLink.includes("/about") ? "active" : ""}
          onClick={() => handleNavLinkClick((userRole === 'Admin') ? "/admin/about" : "/annotator/about")}
        >
          About
        </div>
      </nav>
      <img src={userIcon} className="user-icon" onClick={toggleProfileDropdown} alt="User Icon" />
      {profDropdownIsVisible && (
        <div className="profile-dropdown-wrap">
          <div className="profile-dropdown-user-info">
            <img src={userIcon} className="profile-dropdown-img" alt="User"></img>
            <h4>{username}</h4>
          </div>
          <div className="profile-dropdown-separator"></div>
          <NavLink to="/signin" className="profile-dropdown-link">
            <div className="link-text">
              <img src={infoIcon} alt="Info Icon" />
              <span>Help & support</span>
            </div>
            <span>&gt;</span>
          </NavLink>
          <NavLink to="/signin" className="profile-dropdown-link">
            <div className="link-text">
              <img src={signoutIcon} alt="Signout Icon" />
              <span>Sign Out</span>
            </div>
            <span>&gt;</span>
          </NavLink>
        </div>
      )}
    </header>
  );
}

export default Header;
