import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import api from '../../../axiosApi/api';
import JSZip from 'jszip';

// import AnnotatorsUtilsBar from '../../UtilsBar/AnnotatorsUtilsBar/AnnotatorsUtilsBar';

import "././AnnotatorsDashboard.css";
import './../Dashboard.css';
import naIcon from "../../../Images/naNew.png";
import userIcon from "../../../Images/user.png";
import editIcon from "../../../Images/edit.png"
import deleteIcon from "../../../Images/delete-filled.png"
import ErrorIcon from "../../../Images/error-icon.png";

function AnnotatorsDashboard() {
    const location = useLocation();
    const currentPath = location.pathname;

    const [annotators, setAnnotators] = useState([])
    const [updatedAnnotators, setUpdatedAnnotators] = useState([])
    const [loadingAnnotators, setLoadingAnnotators] = useState(true)
    const [editStates, setEditStates] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [error, setError] = useState(false);

    const fetchAnnotators = async () => {
        try {
            const res = await api.get(`/api/users`, 
                { 
                    params: {  
                        role: "Regular" 
                    },
                    headers: {  
                        Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
                    }
                }
            );
            setError(false);
            setAnnotators(res.data.users);
            setUpdatedAnnotators(res.data.users);
            setEditStates(new Array(res.data.users.length).fill(false));
        } catch (error) {
            setError(true);
            console.error("Error fetching annotators:", error);
        } finally {
            setLoadingAnnotators(false);
        }
    };


    const updateEditState = (userIndex) => {
        setEditStates(prevStates => {
            const newStates = [...prevStates];
            newStates[userIndex] = !newStates[userIndex];
            return newStates;
        });
    };


    const handleRoleChange = (event, userIndex) => {
        const newRole = event.target.value;
    
        setUpdatedAnnotators((prevAnnotators) => 
            prevAnnotators.map((annotator, index) => 
                index === userIndex ? { ...annotator, role: newRole } : annotator
            )
        );
    };


    const checkIfUpdated = (userIndex) => {
        const originalAnnotator = annotators[userIndex];
        const updatedAnnotator = updatedAnnotators[userIndex];
    
        // Check if any relevant fields have changed
        if (originalAnnotator.role !== updatedAnnotator.role) {
            return true;
        }
    
        // Add more field comparisons if needed
        return false;
    };


    const cancelEdit = (userIndex) => {
        const initialAnnotatorState = annotators[userIndex]

        setUpdatedAnnotators((prevAnnotators) => 
            prevAnnotators.map((annotator, index) => 
                index === userIndex ? initialAnnotatorState : annotator
            )
        );

        updateEditState(userIndex)
    }


    const updateUser = async(userIndex) => {
        const updatedAnnotatorState = updatedAnnotators[userIndex]

        try {
            const userId = updatedAnnotators[userIndex].id
            const updatedUser = updatedAnnotators[userIndex]
            
            var updatedRole = updatedUser.role;
            var updatedAccessibleProjects = {
                "removals": "all"
            };
            var updatedAccessibleDataChunks = {
                "removals": "all"
            };

            const res = await api.put(`/api/users/${userId}`, 
                {
                    role: updatedRole,
                    accessible_projects: updatedAccessibleProjects,
                    accessible_data_chunks: updatedAccessibleDataChunks
                },
                {
                    headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') }
                }
            );

            fetchAnnotators();
        } catch (error) {
            console.error("Error fetching data chunk statistics:", error);
            return;
        }
    }


    const deleteUser = async(userIndex) => {
        try {
            const userId = annotators[userIndex].id
            const res = await api.delete(`/api/users/${userId}`, {
                headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
            });

            fetchAnnotators();
        } catch (error) {
            console.error("Error fetching data chunk statistics:", error);
            return;
        }
    }

    
    useEffect(() => {
        fetchAnnotators();
        return () => {};
    }, []);


    return (
        <section className="dashboard annotators-dashboard">
            <h2>Annotators</h2>
            {/* <AdminProjectInstancesUtilsBar 
                filteredData={data}
                setFilteredData={setData}
                projectStatus = {projectStatus}
                setOpenFiltersModal={() => setOpenFiltersModal(true)} 
                setOpenAssignmentsModal={() => setOpenProjectAssignmentsModal(true)}
                setOpenProjectStatusModal={() => setOpenProjectStatusModal(true)}
                exportProjectAnnotations={() => exportProjectAnnotations(projectId)}
            /> */}
            <div className="annotators-container">
            {error ? (
                <div className="message-container">
                    <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                    <p className="message-text-main">An unexpected error occurred.</p> 
                    <p className="message-text">Please try again later.</p>
                </div>
            ) : loadingAnnotators ? (
                <div className="loading-indicator">
                <div className="spinner"></div>
                <span>Loading...</span>
                </div>
            ) : annotators.length > 0 ? (
                <div className="annotators-grid">
                    {annotators.map((annotator, annotatorIndex) => (
                        <div className="annotator-card" key={annotator.id}>
                            <div className="annotator-utils-container">
                                <div className="util-buttons">
                                    <button title="Edit" onClick={() => updateEditState(annotatorIndex)}>
                                        <img src={editIcon} />
                                    </button>
                                    <button title="Delete" onClick={() => deleteUser(annotatorIndex)}>
                                        <img src={deleteIcon} />
                                    </button>
                                </div>
                            </div>
                            <div className="user-avatar-cntainer">
                                <img src={userIcon} />
                            </div>
                            <div className="user-info-container"> 
                                <div className="username">{annotator.username}</div>
                                <div className="user-role-container">
                                    {editStates[annotatorIndex] ? (
                                        <div className="dropdown">
                                            <label htmlFor="role-label">Role: </label>
                                            <select
                                                id="user-role"
                                                value={updatedAnnotators[annotatorIndex].role}
                                                onChange={(event) => handleRoleChange(event, annotatorIndex)} 
                                            >
                                                <option value="Role" disabled>Role</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Admin">Admin</option>
                                            </select>
                                        </div>
                                    ) : (
                                        <span>Role: {annotator.role}</span>
                                    )}
                                </div>
                                <div className="user-projects-container">
                                    <div>Projects:</div>
                                    <div className="projects-list">
                                        {annotator.accessibleProjects.map((project, projectIndex) => (
                                            <div className="list-item" key={project.id} title={project.name}>
                                                {project.name[0]}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="update-btn-container">
                                {editStates[annotatorIndex] && (
                                    <>
                                        <button
                                            onClick={() => cancelEdit(annotatorIndex)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={!checkIfUpdated(annotatorIndex)}
                                            onClick={() => updateUser(annotatorIndex)}
                                        >
                                            Update
                                        </button>
                                    </>
                                )}
                            </div>
                            
                        </div>
                    ))}
                </div>
            ) : (
                <div className="message-container">
                <img src={naIcon} className="message-icon" alt="Not Available" />
                <span className="message-text">No registered annotators.</span>
                </div>
            )}
            </div>
        </section>
    );
}

export default AnnotatorsDashboard;
