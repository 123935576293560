import React, { useState, useEffect } from "react";
import BackgroundOverlay from "../../BackgroundOverlay/BackgroundOverlay";
import xIcon from "../../../Images/x-icon.png";
import "../Modals.css";
import "./CreateProjectModal.css";
import ProjectDetailsStage from "./stages/ProjectDetailsStage/ProjectDetailsStage";
import TemplateSelectionStage from "./stages/TemplateSelectionStage/TemplateSelectionStage";
import LabelSelectionStage from "./stages/LabelSelectionStage/LabelSelectionStage";
import DatasetSelectionStage from "./stages/DatasetSelectionStage/DatasetSelectionStage";
import InstanceSelectionStage from "./stages/InstanceSelectionStage/InstanceSelectionStage";


const CreateProjectModal = ({ open, onClose, submitProject }) => {
  const [workflowQueue, setWorkflowQueue] = useState(["PD", "LSS"]);
  const [currentStage, setCurrentStage] = useState("PD");
  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const [finalStage, setFinalStage] = useState(false);
  const [nextStageEnabled, setNextStageEnabled] = useState(true);

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [preannotatedSetup, setPreannotatedSetup] = useState(false);
  const [selectedModality, setSelectedModality] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [labels, setLabels] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState();
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [selectedChunks, setSelectedChunks] = useState(1);

  useEffect(() => {
    if(selectedTemplate){
      const templateWorkflow = selectedTemplate.workflow.split(',');
      const updatedQueue = ["PD", "LSS", ...templateWorkflow];
      setWorkflowQueue(updatedQueue);
    }
  }, [selectedTemplate])

  useEffect(() => {
    const isNextStageEnabled = () => {
      switch (currentStage) {
        case "PD":
          return projectName !== "";
        case "LSS":
          return selectedTemplate !== undefined;
        case "LS":
          return labels.length > 0;
        case "CS":
          return labels.length > 0;
        case "DS":
          return selectedDataset !== undefined;
        case "IS":
          return selectedInstances.length > 0;
        default:
          return false;
      }
    };
    setNextStageEnabled(isNextStageEnabled());
  }, [currentStage, projectName, selectedTemplate, labels, selectedDataset, selectedInstances]);

  useEffect(() => {
    const isFinalStage = () => {
      if(workflowQueue[workflowQueue.length - 1] === currentStage){
        return true;
      }
    };
    setFinalStage(isFinalStage());
  }, [workflowQueue, currentStage]);

  const switchToNextStage = () => {
    const oldStageIndex = currentStageIndex;
    setCurrentStageIndex(oldStageIndex + 1);
    setCurrentStage(workflowQueue[oldStageIndex + 1]);
  }

  const switchToPreviousStage = () => {
    const oldStageIndex = currentStageIndex;
    setCurrentStageIndex(oldStageIndex - 1);
    setCurrentStage(workflowQueue[oldStageIndex - 1]);
  }

  const handleProjectSubmission = () => {
    submitProject(
      projectName,
      projectDescription,
      preannotatedSetup,
      selectedModality,
      selectedTemplate,
      labels,
      selectedDataset,
      selectedInstances,
      selectedChunks
    ).then((submissionResult) => {
      if (submissionResult.success) {
        clearState();
        onClose();
      } else {
        console.error(submissionResult.error);
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  const clearState = () => {
    setCurrentStageIndex(0);
    setCurrentStage("PD")
    setWorkflowQueue(["PD", "LSS"])
    setFinalStage(false);

    setProjectName("");
    setProjectDescription("");
    setPreannotatedSetup(false);
    setSelectedModality();
    setSelectedTemplate();
    setLabels([]);
    setSelectedDataset();
    setSelectedInstances([]);
    setSelectedChunks(1);
  };

  const onCloseClick = () => {
    clearState();
    onClose();
  };

  if (!open) return null;

  return (
    <>
      <BackgroundOverlay></BackgroundOverlay>
      <div className="creation-modal">
        <div className="creation-header">
          <h3>Create Project</h3>
          <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
        </div>
        <div className="creation-body">
          {currentStage === "PD" && (
            <ProjectDetailsStage
              projectName={projectName}
              setProjectName={setProjectName}
              projectDescription={projectDescription}
              setProjectDescription={setProjectDescription}
              preannotatedSetup={preannotatedSetup}
              setPreannotatedSetup={setPreannotatedSetup}
            />
          )}
          {currentStage === "LSS" && (
            <TemplateSelectionStage
              selectedModality={selectedModality}
              setSelectedModality={setSelectedModality}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          )}
          {currentStage === "LS" && (
            <LabelSelectionStage labels={labels} setLabels={setLabels} />
          )}
          {currentStage === "CS" && (
            <LabelSelectionStage labels={labels} setLabels={setLabels} />
          )}
          {currentStage === "DS" && (
            <DatasetSelectionStage
              selectedDataset={selectedDataset}
              setSelectedDataset={setSelectedDataset}
            />
          )}
          {currentStage === "IS" && (
            <InstanceSelectionStage
              selectedDataset={selectedDataset}
              selectedModality={selectedModality}
              selectedInstances={selectedInstances}
              setSelectedInstances={setSelectedInstances}
              selectedChunks={selectedChunks}
              setSelectedChunks={setSelectedChunks}
            />
          )}
        </div>
        <div className="creation-footer">
          <button
            className={`submission-footer-btn ${currentStageIndex === 0 ? 'hidden' : ''}`}
            onClick={() => switchToPreviousStage()}
          >
            Previous
          </button>
          <button
            disabled={!nextStageEnabled}
            className="submission-footer-btn"
            onClick={finalStage ? handleProjectSubmission : switchToNextStage}
          >
            {finalStage ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectModal;
