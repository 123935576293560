import LabelStudio from 'label-studio';
import api from '../../../axiosApi/api';


async function configureView(projectModality, taskTypeName, taskTypeView, taskTypeAnnotationType, 
                              projectLabels, dataChunkId, instanceRelativePath){
  let labelsConfig = "";
  let configuredView = "";

  // Integrate given labels
  if (taskTypeAnnotationType === "labels") {
    labelsConfig = projectLabels.map((label) => (
      `<Label value="${label.name}" background="${label.color}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$labels/g, labelsConfig);
  } 
  // Integrate given choices
  else if (taskTypeAnnotationType === "choices") {
    labelsConfig = projectLabels.map((label) => (
      `<Choice value="${label.name}" />`
    )).join(''); 
    configuredView = taskTypeView.replace(/\$choices/g, labelsConfig);
  } 
  // Handle rest annotation types
  else if (['text', 'rating'].includes(taskTypeAnnotationType)) {
    configuredView = taskTypeView
  }

  // Handle AdversarialDetection labeling setup
  if (taskTypeName === "AdversarialDetection") {
    // Extract the parent folder path and file name
    const lastSlashIndex = instanceRelativePath.lastIndexOf('/');
    const parentFolderPath = instanceRelativePath.substring(0, lastSlashIndex + 1);
    const fileNameWithExtension = instanceRelativePath.substring(lastSlashIndex + 1);
    const fileName = fileNameWithExtension.split(".")[0];
    const fileExtension = fileNameWithExtension.split(".").slice(-1)[0];

    let importedAudio1 = null;
    let importedAudio2 = null;
    let audioPairName = null;

    // Check if file has perturbated copy or not
    const isPaired = await checkIfInstanceIsPaired(fileName, dataChunkId)
    if(isPaired){
      // Create the new filename and filepath with "_perturbated" appended
      const perturbatedFileName = `${fileName}_perturbated.${fileExtension}`;
      const perturbatedFilePath = parentFolderPath + perturbatedFileName;

      // Make dynamic imports
      importedAudio1 = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
      importedAudio2 = await fetchFile(`/datasets${perturbatedFilePath}`, projectModality);

      audioPairName = fileName + '-' + perturbatedFileName;
    } else{
      importedAudio1 = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
      importedAudio2 = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);

      audioPairName = fileName + '-' + fileName;
    }
    
    // Configure Adversarial view
    configuredView = configuredView.replace(/\$audio1/g, importedAudio1);
    configuredView = configuredView.replace(/\$audio2/g, importedAudio2);
    configuredView = configuredView.replace(/\$audioPairName/g, audioPairName);
  }
  else if (taskTypeName === "ValenceArousal") {
    configuredView = configuredView.replace(/\$instancePathArousal/g, instanceRelativePath+"(Arousal)");
    configuredView = configuredView.replace(/\$instancePathValence/g, instanceRelativePath+"(Valence)");
    
    const instanceFilePath = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
    configuredView = configuredView.replace(/\$srcFile/g, instanceFilePath);
  }
  // Handle rest labeling setups
  else{
    const instanceFilePath = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
    configuredView = configuredView.replace(/\$srcFile/g, instanceFilePath);
  }
  
  return configuredView;
}

async function fetchFile(filePath, projectModality) {
  if(projectModality==="Audio"){
    try {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      return URL.createObjectURL(new Blob([arrayBuffer]));
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  }
  else if(projectModality==="Image"){
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  } 
}

async function checkIfInstanceIsPaired(fileName, dataChunkId) {
  // Create the new filename and filepath with "_perturbated" appended
  const perturbatedFileName = `${fileName}_perturbated`;

  try {
    const res = await api.get(`/api/dataChunks/${dataChunkId}/instances`, {
      headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
      params: {
        name: perturbatedFileName,
      }
    });

    const instances = res.data.instances 
    if (instances && instances.length > 0) {
      return true;
    }

    return false;

  } catch (error) {
    console.error("Error fetching instances:", error);
    return false;
  }
}

export const initializeLabelStudio = async (projectModality, projectTaskType, projectLabels, 
                                            fetchedAnnotations, dataChunkId, instanceRelativePath) => {
  const taskTypeName = projectTaskType["name"];
  const taskTypeView = projectTaskType["view"];
  const taskTypeAnnotationType = projectTaskType["annotation_type"];
  
  const viewConfig = await configureView(projectModality["name"], taskTypeName, taskTypeView, taskTypeAnnotationType, 
                                          projectLabels, dataChunkId, instanceRelativePath);
  const interfaceConfig = [
    "controls",
    "side-column",
    "annotations:menu",
  ];
  const labelStudioConfig = {
    config: viewConfig,
    interfaces: interfaceConfig,
    user:{
      pk: 1,
      firstName: "",
      lastName: "",
    },
    task: {
      annotations: fetchedAnnotations,
    },
    onLabelStudioLoad: function (LS) {
      if(fetchedAnnotations.length === 0){
        var c = LS.annotationStore.addAnnotation({
            userGenerate: false,
            createdBy: "System",
            id: "Empty Annotation",
            createdDate: new Date()
        });
        LS.annotationStore.selectAnnotation(c.id);
      }
      else {
        let annotation =  LS.annotationStore.annotations
        console.log(annotation)
      }
    },
    onSubmitAnnotation: async function (LS, annotation) {}
  };

  const labelStudio = new LabelStudio("label-studio", labelStudioConfig);
};
