import ConceptualDesignImg from "../../../Images/ConceptualDesign.png";
import UserDatasetRelImg from "../../../Images/UserDatasetRel.png";
import InstanceDatasetImg from "../../../Images/InstanceDatasetRel.png";
import UserProjectImg from "../../../Images/UserProjectRel.png";
import UserDataChunkImg from "../../../Images/UserDataChunkRel.png";
import ProjectDataChunkImg from "../../../Images/ProjectDataChunkRel.png";
import DataChunkInstanceImg from "../../../Images/DataChunkInstanceRel.png";
import ProjectModalityImg from "../../../Images/ProjectModalityRel.png";
import ProjectTaskTypeImg from "../../../Images/ProjectTaskTypeRel.png";
import TaskTypeModalityImg from "../../../Images/TaskTypeModalityRel.png";
import UserAnnotationImg from "../../../Images/UserAnnotationRel.png";
import AnnotationInstanceImg from "../../../Images/AnnotationInstanceRel.png";
import AnnotationDataChunkImg from "../../../Images/AnnotationDataChunkRel.png";
import MongoCollectionsImg from "../../../Images/MongoCollections.png";

function Database() {
    return (
        <>
            <h3>Database Design</h3>
            <p>A good database design is essential for creating a structured and efficient data storage system that meets the needs of a platform. It involves organizing data in a logical and meaningful way, defining relationships between data entities, ensuring data integrity through constraints and validations, optimizing query performance, and considering scalability and security requirements. In the context of Task 3.3, a well-designed database not only will improve the pipeline performance and responsiveness but will also reduce the risk of data inconsistencies, enhance data security measures, support scalability as data grows, and enable easier data analysis and reporting. This chapter is focused on the thought process behind the design of the pipeline database.</p>
        
            <h4>Relational VS Document approach</h4>
            <p>One of the most crucial decisions that had to be made at the very beginning of the design process and that would determine the whole development workflow of the platform is the choice between relational and document databases.</p>
        
            <p>Document databases, such as MongoDB, excel in handling unstructured or semi-structured data by storing information in flexible JSON or BSON documents. They offer schema flexibility, allowing for dynamic changes to data structures without disrupting existing data. However, document databases may face challenges in complex query scenarios that require joins across multiple collections or tables, impacting query performance.</p>
            <p>On the other hand, relational databases are collections of data items organized as a set of formally described tables. This category of databases excels in maintaining structured data with predefined schemas and enforcing data integrity through relationships. They are optimized for complex queries involving joins, aggregations, and transactions, making them suitable for applications with complex data relationships. Relational databases also provide robust ACID (Atomicity, Consistency, Isolation, Durability) compliance, ensuring data consistency and reliability.</p>

            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Aspect</th>
                        <th>Relational Database</th>
                        <th>Document Database</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Database Categorization</td>
                        <td>Relational</td>
                        <td>Document</td>
                    </tr>
                    <tr>
                        <td>Data Structure</td>
                        <td>Tables with rows and columns</td>
                        <td>JSON, BSON, or XML-like documents</td>
                    </tr>
                    <tr>
                        <td>Schema</td>
                        <td>Requires predefined schema and changes can be complex</td>
                        <td>Schema is dynamic and supports alteration</td>
                    </tr>
                    <tr>
                        <td>Scalability</td>
                        <td>Vertical scalability (Centralization)</td>
                        <td>Horizontal scalability (Decentralization)</td>
                    </tr>
                    <tr>
                        <td>Data Integrity</td>
                        <td>High</td>
                        <td>Varies</td>
                    </tr>
                    <tr>
                        <td>Relationships</td>
                        <td>Strong relationships using foreign keys</td>
                        <td>Embedded documents and linking are supported</td>
                    </tr>
                </tbody>
            </table>

            <p>The decision to opt for a relational or a non-relational database for the annotation pipeline was primarily driven by the requirement for schema flexibility. While a significant portion of the initial data in the pipeline could have been structured as tabular entities, the evolving and experimental nature of Task 3.3 necessitated a more adaptable approach. Therefore, the flexibility provided by document databases, such as MongoDB, proved crucial in accommodating the pipeline data effectively. Additionally, document databases like MongoDB are designed for horizontal scalability, allowing them to distribute data across multiple nodes and handle increased query loads. This scalability can be advantageous in scenarios with high query volumes and data throughput requirements, such as an annotation task distributed to numerous annotations.</p>
            
            <h4>Requirements and Conceptual design</h4>
            <p>Defining requirements is the initial step in designing a database model. This step involved analyzing the needs of the annotation pipeline and the peculiarities of different annotation tasks, with the aim of identifying key entities relevant to the application, their attributes, relationships between entities, and any constraints or rules that govern the data.</p>
            <p>The conceptual design of the database holds as follows:</p>

            <div className="img-container">
                <img src={ConceptualDesignImg}/>
            </div>

            <h5>Key Entities</h5>
            <p>As a result of detailed analysis, the key entities that interact and constitute the data model of the proposed pipeline are the following:</p>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Entity Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>User</td>
                        <td>Represents individuals interacting with the pipeline system</td>
                    </tr>
                    <tr>
                        <td>Dataset</td>
                        <td>Refers to a collection of files that are parsed and loaded into the annotation platform with the aim of annotating them</td>
                    </tr>
                    <tr>
                        <td>Modality</td>
                        <td>Describes the type of files being annotated within the pipeline</td>
                    </tr>
                    <tr>
                        <td>Task Type</td>
                        <td>Specifies the type of annotation task</td>
                    </tr>
                    <tr>
                        <td>Project</td>
                        <td>Represents an annotation project</td>
                    </tr>
                    <tr>
                        <td>Instance</td>
                        <td>Refers to a file belonging to a dataset</td>
                    </tr>
                    <tr>
                        <td>Data Chunk</td>
                        <td>Describes a group/subset of files that belong to the same dataset</td>
                    </tr>
                    <tr>
                        <td>Annotation</td>
                        <td>Specifies an annotation submitted by an annotator for a given instance</td>
                    </tr>
                </tbody>
            </table>

            <h5>Relationships</h5>
            <p>Relationships are fundamental to modeling the structure of a database and defining how entities are related or connected to each other based on the business logic. In fact, numerous relationships were identified between the key entities of the pipeline data model. The present section delves into these relationships.</p>
            
            <ul>
                <li>Each <b>User</b> (only administrators) has the capability to <u>upload multiple</u> <b>Datasets</b>, but each <b>Dataset</b> <u>is uploaded by only one</u> <b>User</b>.</li>
                <li>Each <b>Dataset</b> <u>includes one or many</u> Instances, while an <b>Instance</b> <u>belongs to only one</u> <b>Dataset</b>.</li>
                <li>A <b>User</b> (only administrators) is also able to <u>create multiple</u> Projects, but each <b>Project</b> <u> is created by a single</u> <b>User</b>. </li>
                <li>Each <b>User</b> <u>has access to one or more</u> <b>Projects</b> and, at the same time, each <b>Project</b> <u>is accessible by one or more</u> <b>Users</b>.</li>
                <li>A <b>User</b> <u>has access to one or more</u> <b>Data Chunks</b> and each <b>Data Chunk</b> <u>can be accessed by multiple</u> <b>Users</b>.</li>
                <li>A <b>Data Chunk</b> <u>belongs to only one</u> <b>Project</b>. On the other hand, a <b>Project</b> <u>includes one or more</u> <b>Data Chunks</b>.</li>
                <li>A <b>Data Chunk</b> <u>includes one or more</u> <b>Instances</b> and an <b>Instance</b> <u>can be present in multiple</u> <b>Data Chunks</b>.</li>
                <li>Each <b>Project</b> <u>has only one</u> <b>Modality</b> which dictates the mode of files that can be annotated within it. However, many <b>Projects</b> <u>can have the same</u> <b>Modality</b>.</li>
                <li>A <b>Project</b> <u>has a certain</u> <b>Task Type</b>, which defines the annotation setup under which the files are going to be annotated. Multiple <b>Projects</b> <u>can have the same</u> <b>Task Type</b>.</li>
                <li>Each <b>Task Type</b> <u>is bound to a single</u> <b>Modality</b>. Expectedly, <u>multiple</u> <b>Task Types</b> <u>can have the same</u> <b>Modality</b>.</li>
                <li>A <b>User</b> (only annotators) <u>can submit multiple</u> <b>Annotations</b>, with each <b>Annotation</b> <u>being submitted by a single</u> <b>Annotator</b>.</li>
                <li>Each submitted <b>Annotation</b> <u>corresponds to a specific</u> <b>Instance</b> and the <b>Data Chunk</b> containing that Instance. Multiple <b>Annotations</b> can <u>refer to the same</u> <b>Instance</b> and the same <b>Data Chunk</b>.</li>
            </ul>

            <h5>Translation of ER to MongoDB document models</h5>
            <p>Despite the inherent differences between relational and document databases, the process of translating the ER diagram into a logical representation of the MongoDB database borrowed several elements from the fundamental guidelines for transforming an ER diagram to a relational model. In more detail, the successive steps of the transformation process hold as follows:</p>
            <ol>
                <li>For each non-weak entity type build a document model including all attributes and define primary key</li>
                <li>For each weak entity type W, having E as owner, build a document model including all attributes of W and add E’s PK attributes as a reference</li>
                <li>For each 1:1 relationship between E1 & E2 entities, select one of them to get as reference the PK of the other, along with all attributes of the relationship</li>
                <li>For each 1:N relationship between E1 & E2, select E2 to have a reference to the PK of E1 and get all attributes of the relationship</li>
                <li>For each M:N relationship between E1 & E2, select E1 to hold a list of all references to PKs of E2 documents</li>
            </ol>

            <p>Regarding many to many relationships in MongoDB, there are two approaches to model the documents: (i) Embedding documents and (ii) Using document references. In an embedded relationship, one document contains the data of another related document as a nested subdocument or array of subdocuments. In a reference relationship, one document refers to the related document using references such as ObjectIDs (usually _id fields of documents).</p>
        
            <p>While embedded relationships offer simplicity in data retrieval, they can lead to larger document sizes if arrays grow significantly, potentially impacting performance. Moreover, embedded documents in MongoDB may cause data redundancy if not managed carefully, leading to challenges in maintaining a clean and consistent database. Therefore, to prioritize database cleanliness, consistency, and scalability, the document reference approach was favored.</p>
        
            <h5>Definition of document models</h5>
            <p>Following the previously outlined steps, document models were designed for each entity represented in the ER diagram. Below, the document models for the MongoDB database are presented.</p>

            <h6>User Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>username</td>
                        <td>String</td>
                        <td>Required, Unique, Valid</td>
                    </tr>
                    <tr>
                        <td>hashed_pwd</td>
                        <td>String</td>
                        <td>Required, Valid</td>
                    </tr>
                    <tr>
                        <td>role</td>
                        <td>String</td>
                        <td>Default = “Regular”</td>
                    </tr>
                    <tr>
                        <td>accessible_projects</td>
                        <td>List of Project References</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>accessible_data_chunks</td>
                        <td>List of DataChunk References</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <h6>Dataset Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>upload_date</td>
                        <td>Date Time</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>uploaded_by</td>
                        <td>User Reference</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <h6>Task Type Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>view</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>annotation_type</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>modality</td>
                        <td>Modality Reference</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h6>Modality Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>file_types</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h6>Project Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>String</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>modality</td>
                        <td>Modality Reference</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>task_type</td>
                        <td>TaskType Reference</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>labels</td>
                        <td>List of Dictionaries</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>data_chunks</td>
                        <td>List of DataChunk References</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>created_by</td>
                        <td>User Reference</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>creation_date</td>
                        <td>Date Time</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>status</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h6>Data Chunk Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>instance_refs</td>
                        <td>List of Instance References</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h6>Instance Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>relative_path</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>file_type</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>file_size</td>
                        <td>Integer</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>parent_dataset</td>
                        <td>Dataset Reference</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h6>Annotation Model</h6>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Type</th>
                        <th>Constraints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_id</td>
                        <td>Object ID</td>
                        <td>Primary Key</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>relative_path</td>
                        <td>String</td>
                        <td>Required, Unique</td>
                    </tr>
                    <tr>
                        <td>file_type</td>
                        <td>String</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>file_size</td>
                        <td>Integer</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>parent_dataset</td>
                        <td>Dataset Reference</td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>

            <h4>Database Setup</h4>
            <p>The final step towards implementing the database of the annotation pipeline was the actual setup of the database. By following a structured approach of conceptual and logical modeling, the gap between the requirements of Task 3.3 and the technical implementation of the database was bridged, leading to an effective database system. </p>
            <p>Specifically, a database called “AnnotationPipelineDB” was created using the MongoDB Compass tool. This database holds all the data required for the seamless operation of the pipeline. When deployed locally, the database service listens on port 27017, facilitating communication with the Flask back-end server.</p>
            <p>As already highlighted, the data used by the pipeline are organized in collections of documents. On this basis, the collections which comprise the pipeline database are illustrated in the following picture.</p>
        
            <div className="img-container">
                <img src={MongoCollectionsImg}/>
            </div>
        </> 
    )
}

export default Database;