import LabelStudio from 'label-studio';
import api from '../../../axiosApi/api';


async function configureView(projectModality, projectLabels, taskView, annotationType, instanceRelativePath){
  let labelsConfig = "";
  let configuredView = "";

  // Integrate given labels
  if (annotationType === "labels") {
    labelsConfig = projectLabels.map((label) => (
      `<Label value="${label.name}" background="${label.color}" />`
    )).join(''); 
    configuredView = taskView.replace(/\$labels/g, labelsConfig);
  } 
  else if(annotationType === "choices") {
    labelsConfig = projectLabels.map((label) => (
      `<Choice value="${label.name}" />`
    )).join(''); 
    configuredView = taskView.replace(/\$choices/g, labelsConfig);
  }

  const instanceFilePath = await fetchFile(`/datasets${instanceRelativePath}`, projectModality);
  configuredView = configuredView.replace(/\$srcFile/g, instanceFilePath);

  const regex = /<Header[^>]*\/>/g;
  configuredView = configuredView.replace(regex, '');
  
  return configuredView;
}

async function fetchFile(filePath, projectModality) {
  if(projectModality==="Audio"){
    try {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      return URL.createObjectURL(new Blob([arrayBuffer]));
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  }
  else if(projectModality==="Image"){
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  } 
}


//projectModality, annotationType, projectLabels, instanceRelativePath
export const initializeLabelStudio = async (taskView, projectModality, projectLabels, annotationType, 
                                            instanceRelativePath, groundTruthAnnotations) => {
  
  const viewConfiguration = await configureView(projectModality, projectLabels, taskView, annotationType, instanceRelativePath);
  const interfaceConfig = [
    "controls",
    "side-column",
    "annotations:menu",
  ];
  const labelStudioConfig = {
    config: viewConfiguration,
    interfaces: interfaceConfig,
    task: {
      annotations: groundTruthAnnotations
    },
    onLabelStudioLoad: function (LS) {
      if(groundTruthAnnotations.length === 0){
        var c = LS.annotationStore.addAnnotation({
            userGenerate: false,
            createdBy: "system",
            id: "Ground Truth",
        });
        LS.annotationStore.selectAnnotation(c.id);
      }
    },
    onSubmitAnnotation: async function (LS, annotation) {}
  };

  const labelStudio = new LabelStudio("label-studio", labelStudioConfig);
};
