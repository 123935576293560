import React, { useState, useEffect, useRef } from "react";
import { initializeLabelStudio } from "../GroundTruthLabelStudioConfig.js";
import JSZip from 'jszip';
import api from '../../../../axiosApi/api';
import BackgroundOverlay from "../../../BackgroundOverlay/BackgroundOverlay";
import xIcon from "../../../../Images/x-icon.png";
import naIcon from "../../../../Images/naNew.png";
import infoIcon from '../../../../Images/info.png';
import ErrorIcon from "../../../../Images/error-icon.png";

import "../../Modals.css";
import "../StatisticsModal.css";
import "./DataChunkStatisticsModal.css";


const DataChunkStatisticsModal = ({ taskType, dataChunkId, dataChunkNumber, dataChunkInstances, 
                                    taskView, annotationType, projectModality, projectLabels, onClose }) => {
  const [loadingDataChunkStatistics, setLoadingDataChunkStatistics] = useState(true);
  const [loadingInstanceStatistics, setLoadingInstanceStatistics] = useState(false);

  const [totalInstances, setTotalInstances] = useState(null);
  const [totalAnnotators, setTotalAnnotators] = useState(null);
  const [assignedAnnotators, setAssignedAnnotators] = useState([]);
  const [avgDataChunkProgress, setAvgDataChunkProgress] = useState(null);
  const [microavgDataChunkAnnotTime, setMicroavgDataChunkAnnotTime] = useState(null);
  const [macroavgDataChunkAnnotTime, setMacroavgDataChunkAnnotTime] = useState(null);
  const [microstdDataChunkAnnotTime, setMicrostdDataChunkAnnotTime] = useState(null);
  const [macrostdDataChunkAnnotTime, setMacrostdDataChunkAnnotTime] = useState(null);
  const [dataChunkAgreementStats, setDataChunkAgreementStats] = useState([]);
  const [pairwiseAgreement, setPairwiseAgreement] = useState(null);
  const [cohenThresholdValues, setCohenThresholdValues] = useState([]);
  const [instancesThresholdValues, setInstancesThresholdValues] = useState([]);
  const [excludedUsers, setExcludedUsers] = useState([])
  const [pendingUpdate, setPendingUpdate] = useState(false);
  const [isExcludedUsersSet, setIsExcludedUsersSet] = useState(false);
  const tableRefs = useRef([]);
  const [groundTruthAnnotations, setGroundTruthAnnotations] = useState([]);
  const [dataChunkJSONFile, setDataChunkJSONFile] = useState(null);

  const [selectedInstanceId, setSelectedInstanceId] = useState(null)
  const [selectedInstanceIndex, setSelectedInstanceIndex] = useState(null);
  const [instanceAnnotationProgress, setInstanceAnnotationProgress] = useState(null);
  const [instanceFinishedAnnotators, setInstanceFinishedAnnotators] = useState([]);
  const [instancePendingAnnotators, setInstancePendingAnnotators] = useState([]);
  const [instanceAvgAnnotationTime, setInstanceAvgAnnotationTime] = useState(null);
  const [instanceStdAnnotationTime, setInstanceStdAnnotationTime] = useState(null);
  const [instanceAnnotationsCharts, setInstanceAnnotationsCharts] = useState([]);
  const [currentChartIndex, setCurrentChartIndex] = useState(0);
  const [selectedSimilarityMetric, setSelectedSimilarityMetric] = useState('Similarity metric');
  const [selectedSimilarityThreshold, setSelectedSimilarityThreshold] = useState('0.5')
  const [instanceJSONFile, setInstanceJSONFile] = useState(null);

  const [error, setError] = useState(false);

  
  // Fetch data chunk statistics when page loads
  useEffect(() => {
    fetchDataChunkStatistics(dataChunkId);
  }, []);

  // Fetch updated statistics when exclusion of annotators is completed
  useEffect(() => {
    if (isExcludedUsersSet) {
      setPendingUpdate(false);  
      fetchDataChunkStatistics(dataChunkId);
      setIsExcludedUsersSet(false); 
    }
  }, [isExcludedUsersSet]);
  
  // Add or remove an annotator from the list of excluded annotators
  const handleUserExclusion = (annotatorId, annotatorUsername) => {
    const updatedExcludedUsers = [...excludedUsers];    
    
    const userIndex = updatedExcludedUsers.findIndex(
      ([id, username]) => id === annotatorId && username === annotatorUsername
    );
    
    if (userIndex > -1)
      updatedExcludedUsers.splice(userIndex, 1);
    else
      updatedExcludedUsers.push([annotatorId, annotatorUsername]);
    
    setExcludedUsers(updatedExcludedUsers);
    setPendingUpdate(true);
  };

  // Get outlying usernames from referenced table and add them to the list of excluded annotators
  const excludeOutlyingAnnotators = async (tableIndex) => {
    const table = tableRefs.current[tableIndex];
    if (table) {
      const outlierRows = table.querySelectorAll('tr.outlier');
      const outlierUsernames = Array.from(outlierRows).map(row => {
        const firstTd = row.querySelector('td:first-child');
        return firstTd ? firstTd.textContent : null;
      });
  
      setExcludedUsers((prevExcludedUsers) => {
        const updatedExcludedUsers = [...prevExcludedUsers];
  
        outlierUsernames.forEach(outlierUsername => {
          const userIndex = updatedExcludedUsers.findIndex(
            ([id, username]) => username === outlierUsername
          );
  
          if (userIndex > -1) {
            updatedExcludedUsers.splice(userIndex, 1);
          } else {
            const userInfo = assignedAnnotators.find(
              user => user.username === outlierUsername
            );
  
            if (userInfo) {
              updatedExcludedUsers.push([userInfo.id, userInfo.username]);
            }
          }
        });
  
        return updatedExcludedUsers;
      });
  
      setIsExcludedUsersSet(true);
    }
  };
  
  // Handle change of pairwise Cohen's k threshold
  const handleCohenThresholdChange = (e, index) => {
    const inputValue = e.target.value;

    if (inputValue === '' || (inputValue >= -1 && inputValue <= 1)) {
      setCohenThresholdValues(prevValues => 
        prevValues.map((value, i) => (i === index ? inputValue : value))
      );
    }
  };

  // Handle change of pairwise Cohen's k threshold
  const handleInstancesThresholdChange = (e, index) => {
    const inputValue = e.target.value;

    if (inputValue === '' || (inputValue >= 0)) {
      setInstancesThresholdValues(prevValues => 
        prevValues.map((value, i) => (i === index ? inputValue : value))
      );
    }
  };

  // Fetch data chunk statistics
  const fetchDataChunkStatistics = async (dataChunkId) => {
    setLoadingDataChunkStatistics(true);
    try {
      const res = await api.get(`/api/data-chunks/${dataChunkId}/stats`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: {
          projectType: taskType,
          annotationType: annotationType,
          excludedUsers: excludedUsers.map(([annotatorId, _]) => annotatorId).join(',')
        },
        timeout: 60000
      });
      setError(false);
      setTotalInstances(res.data.totalInstances);
      setAssignedAnnotators(res.data.assignedAnnotators);
      setTotalAnnotators(res.data.assignedAnnotators.length);
      setAvgDataChunkProgress(res.data.avgProgress);
      setMacroavgDataChunkAnnotTime(res.data.macroavgAnnotationTime);
      setMicroavgDataChunkAnnotTime(res.data.microavgAnnotationTime);
      setMacrostdDataChunkAnnotTime(res.data.macrostdAnnotationTime);
      setMicrostdDataChunkAnnotTime(res.data.microstdAnnotationTime);
      if (taskType === "ValenceArousal" || taskType === "AdversarialDetection") {
        setDataChunkAgreementStats(res.data.agreementStats);
        setPairwiseAgreement(res.data.pairwiseAgreement);
        if(res.data.pairwiseAgreement !== null){
          setCohenThresholdValues(res.data.pairwiseAgreement.map((item, index) => 0));
          setInstancesThresholdValues(res.data.pairwiseAgreement.map((item, index) => 0));
        }
        else{
          setCohenThresholdValues([])
          setInstancesThresholdValues([])
        }
      } 
      setDataChunkJSONFile(res.data.jsonFile);
      clearInstanceStatsState()
      setLoadingDataChunkStatistics(false);
    } catch (error) {
      setError(true);
      console.error("Error fetching data chunk statistics:", error);
      setLoadingDataChunkStatistics(false);
    }
  };

  // Fetch instance statistics
  const fetchInstanceStatistics = async (instanceId, instanceIndex) => {
    try {
      setLoadingInstanceStatistics(true);

      let params_dict = {
        projectType: taskType,
        annotationType: annotationType,
        excludedUsers: excludedUsers.map(([annotatorId, _]) => annotatorId).join(',')
      };
      
      if (["RectangleLabels", "PolygonLabels"].includes(taskType)) {
        params_dict["groundTruthSettings"] = {
          similarity_metric: selectedSimilarityMetric === "Similarity metric" ? "IoU" : selectedSimilarityMetric,
          similarity_threshold: selectedSimilarityThreshold
        };
      }

      const res = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/stats`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: params_dict,
        timeout: 10000
      });

      setError(false);
      setInstanceAnnotationProgress(res.data.annotationProgress);
      setInstanceFinishedAnnotators(res.data.annotators.finishedAnnotators);
      setInstancePendingAnnotators(res.data.annotators.pendingAnnotators);
      setInstanceAvgAnnotationTime(res.data.avgAnnotationTime);
      setInstanceStdAnnotationTime(res.data.stdAnnotationTime);

      if (taskType === "ValenceArousal" || taskType === "AdversarialDetection") {
        setInstanceAnnotationsCharts(res.data.agreementCharts);
      } else if (taskType === "RectangleLabels" || taskType === "PolygonLabels") {
        setGroundTruthAnnotations(res.data.groundTruthAnnotations);
        initializeLabelStudio(
          taskView,
          projectModality, 
          projectLabels, 
          annotationType,
          dataChunkInstances[instanceIndex].relativePath,
          res.data.groundTruthAnnotations
        );
      }
      setInstanceJSONFile(res.data.jsonFile);
      setLoadingInstanceStatistics(false);
    } catch (error) {
      setError(true);
      console.error("Error fetching instance statistics:", error);
      setLoadingInstanceStatistics(false);
    }
  };

  // Handle view of instance agreement charts
  const viewNextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % instanceAnnotationsCharts.length);
  };

  const viewPrevChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex - 1 + instanceAnnotationsCharts.length) % instanceAnnotationsCharts.length);
  };

  // Configure styling of Ground Truth estimation section
  const configureGroundTruthStyle = () => {
    if (taskType === "PolygonLabels"){
      import("../LabelStudioStyles/GroundTruthPolygonLabels.css");
      return("ground-truth-polygon-labels")
    } 
    else if (taskType === "RectangleLabels"){
      import("../LabelStudioStyles/GroundTruthRectangleLabels.css");
      return("ground-truth-rectangle-labels")
    } 
    else if (taskType === "ImageClassification"){
      import("../LabelStudioStyles/GroundTruthImageClassification.css");
      return("ground-truth-image-classification");
    } 
    else if (taskType === "ImageCaptioning"){
      import("../LabelStudioStyles/GroundTruthImageCaptioning.css");
      return("ground-truth-image-captioning");
    } 
    else if (taskType === "AudioCaptioning"){
      import("../LabelStudioStyles/GroundTruthAudioCaptioning.css");
      return("ground-truth-audio-captioning");
    } 
    else if (taskType === "AudioClassification"){
      import("../LabelStudioStyles/GroundTruthAudioClassification.css");
      return("ground-truth-audio-classification");
    } 
    else if (taskType === "SegmentsAudioClassification"){
      import("../LabelStudioStyles/GroundTruthSegmentsAudioClassification.css");
      return("ground-truth-segments-audio-classifiction");
    } 
    else if (taskType === "SegmentsAudioCaptioning"){
      import("../LabelStudioStyles/GroundTruthSegmentsAudioCaptioning.css");
      return("ground-truth-segments-audio-captioning");
    } 
    else if (taskType === "ValenceArousal"){
      import("../LabelStudioStyles/GroundTruthValenceArousal.css");
      return("ground-truth-valence-arousal");
    } 
    else if (taskType === "AdversarialDetection"){
      import("../LabelStudioStyles/GroundTruthAdversarialDetection.css");
      return("ground-truth-adversarial-detection");
    } 
  }

  // Handle change of ground truth estimation metric
  const handleSimilarityMetricChange = (event) => {
    setSelectedSimilarityMetric(event.target.value);
    fetchInstanceStatistics(selectedInstanceId, selectedInstanceIndex);
  };

  // Handle change of ground truth estimation metric threshold
  const handleThresholdValueChange = (e) => {
    let newValue = e.target.value;

    if (newValue === '' || (/^\d*\.?\d*$/.test(newValue) && newValue <= 1)) {
      if (newValue.includes('.')) {
        const [integer, decimal] = newValue.split('.');
        if (decimal.length > 2) {
          return;
        }
      }
      setSelectedSimilarityThreshold(newValue);
    }
  };

  // Handle accepted values for ground truth estimation metric threshold
  const handleThresholdBlur = () => {
    if (selectedSimilarityThreshold !== '') {
      const numericValue = parseFloat(selectedSimilarityThreshold);
      if (numericValue > 1) {
        setSelectedSimilarityThreshold('1.00');
      } else {
        setSelectedSimilarityThreshold(numericValue.toFixed(2));
      }
      fetchInstanceStatistics(selectedInstanceId, selectedInstanceIndex);
    }
  };

  // Export statistics zip
  const exportStats = async () => {
    if (!dataChunkJSONFile && !instanceJSONFile && instanceAnnotationsCharts.length === 0) {
      console.error("No JSON file or charts available to export.");
      return;
    }
  
    const utf8Encoder = new TextEncoder();
    const zip = new JSZip();
  
    if (dataChunkJSONFile) {
      const dataChunkJsonFileContent = atob(dataChunkJSONFile);
      try {
        const dataChunkJsonData = JSON.parse(dataChunkJsonFileContent);
        const dataChunkJsonString = JSON.stringify(dataChunkJsonData, null, 2);
        const dataChunkUtf8Content = utf8Encoder.encode(dataChunkJsonString);
        if (typeof dataChunkNumber !== 'undefined') {
          zip.file(`data_chunk_${dataChunkNumber}_statistics.json`, dataChunkUtf8Content);
        } else {
          console.error("dataChunkNumber is not defined.");
        }
      } catch (error) {
        console.error("Error exporting data chunk JSON file:", error);
      }
    }
  
    if (instanceJSONFile) {
      const instanceJsonFileContent = atob(instanceJSONFile);
      try {
        const instanceJsonData = JSON.parse(instanceJsonFileContent);
        const instanceJsonString = JSON.stringify(instanceJsonData, null, 2);
        const instanceUtf8Content = utf8Encoder.encode(instanceJsonString);
        if (typeof selectedInstanceIndex !== 'undefined' && dataChunkInstances && dataChunkInstances[selectedInstanceIndex]) {
          zip.file(`instance_${dataChunkInstances[selectedInstanceIndex]['name']}_statistics.json`, instanceUtf8Content);
        } else {
          console.error("selectedInstanceIndex or dataChunkInstances is not defined or invalid.");
        }
      } catch (error) {
        console.error("Error exporting instance JSON file:", error);
      }
    }
  
    // Add charts to the zip file
    instanceAnnotationsCharts.forEach((chartBase64, index) => {
      const chartBinary = Uint8Array.from(atob(chartBase64), c => c.charCodeAt(0));
      zip.file(`chart_${index + 1}.png`, chartBinary);
    });
  
    try {
      const zipContent = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipContent);
      link.download = 'statistics.zip';
      link.click();
    } catch (error) {
      console.error("Error generating zip file:", error);
    }
  };

  // Clear state of instance statistics
  const clearInstanceStatsState = () => {
    setSelectedInstanceId(null);
    setSelectedInstanceIndex(null);
    setInstanceAnnotationProgress(null);
    setInstanceFinishedAnnotators([]);
    setInstancePendingAnnotators([]);
    setInstanceAvgAnnotationTime(null);
    setInstanceStdAnnotationTime(null);
    setInstanceAnnotationsCharts([]);
    setCurrentChartIndex(0);
    setSelectedSimilarityMetric('Similarity metric');
    setSelectedSimilarityThreshold('0.5');
  }


  return (
    <>
      <BackgroundOverlay />
      <div className="modal statistics-modal">
        <div className="modal-header">
          <h3>Statistics</h3>
          <img src={xIcon} className="x-icon" onClick={onClose} alt="Close Icon" />
        </div>
        <div className="modal-body statistics-body">
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) :loadingDataChunkStatistics ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <>
              <div className="data-chunk-name-container">
                <div className="data-chunk-name">Data Chunk {dataChunkNumber}</div>
                {pendingUpdate && <div className="update-statistics-btn" onClick={()=>setIsExcludedUsersSet(true)}>Update statistics</div>}
              </div>
              <div className="data-chunk-statistics-content">
                <div className="data-chunk-stats">
                  <div className="stats-row">
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation Progress</div>
                      <div className="stats-block-body">
                        <div>Number of Instances: {totalInstances}</div>
                        <div>
                          <div>Annotators: {totalAnnotators}</div>
                          <div className="stat-annotators-list">
                            {assignedAnnotators && assignedAnnotators.map((annotator) => (
                              <div 
                                className={`annotator-icon ${excludedUsers.some(
                                  ([id, username]) => id === annotator.id && username === annotator.username
                                ) ? 'excluded' : ''}`}                              
                                key={annotator.user_id} 
                                title={annotator.username}
                                onClick={() => handleUserExclusion(annotator.id, annotator.username)}
                              >
                                {annotator.username[0]}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div>
                          <div>Avg. Progress: {avgDataChunkProgress !== null ? avgDataChunkProgress : "?"}%</div>
                          <div className="progress-bar">
                            <div className="completion-bar" style={{ width: `${avgDataChunkProgress !== null ? avgDataChunkProgress : 0}%` }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation Time</div>
                      <div className="stats-block-body">
                        <div>Micro Avg. Annotation Time: {microavgDataChunkAnnotTime !== null ? microavgDataChunkAnnotTime : "?"} sec.</div>
                        <div>Micro Std. Annotation Time: {microstdDataChunkAnnotTime !== null ? microstdDataChunkAnnotTime : "?"} sec.</div>
                        <div>Macro Avg. Annotation Time: {macroavgDataChunkAnnotTime !== null ? macroavgDataChunkAnnotTime : "?"} sec.</div>
                        <div>Macro Std. Annotation Time: {macrostdDataChunkAnnotTime !== null ? macrostdDataChunkAnnotTime : "?"} sec.</div>
                      </div>
                    </div>
                    {dataChunkAgreementStats.length > 0 && (
                      <div className="stats-block">
                        <div className="stats-block-header">Agreement</div>
                        <div className="stats-block-body">
                            {dataChunkAgreementStats.map((agreementStat) => (
                              <div key={agreementStat.name} className="agreement-stat-block">
                                <div className="metric-value-evaluation">
                                  <div>{agreementStat.name}: {agreementStat.value}</div>
                                  <div
                                    className="evaluation-circle"
                                    title={agreementStat.evaluation}
                                    style={{ backgroundColor: agreementStat.evaluationColor }}
                                  />
                                </div>
                                <a className="info-icon" href={agreementStat.link} target="_blank" rel="noopener noreferrer">
                                  <img src={infoIcon} alt="Info Icon" />
                                </a>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {pairwiseAgreement.length > 0 && (
                    <div className="stats-row pairwise-agreement-row">
                      <div className="stats-block">
                        <div className="stats-block-header">Pairwise Agreement</div>
                        <div className="stats-block-body">
                          {pairwiseAgreement.map((item, index) => (
                            <div key={index}>
                              {item.taskName && <h5>{item.taskName}</h5>}
                              <h6>Pairwise Cohen's K Matrix (num. of annotated instances)</h6>
                              <table className="pairwise-cohens-table">
                                <thead>
                                  <tr>
                                    <th></th>
                                    {item.annotator_ids.map((id, idIndex) => (
                                      <th key={idIndex} title={id}>{id}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.matrix_list.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      <th title={item.annotator_ids[rowIndex]}>{item.annotator_ids[rowIndex]}</th>
                                      {row.map((value, colIndex) => (
                                        <td key={colIndex}>
                                          {value !== null ? value : 'N/A'} ({item.annotated_instances_list[rowIndex] && item.annotated_instances_list[rowIndex][colIndex] ? item.annotated_instances_list[rowIndex][colIndex] : Math.max(...item.annotated_instances_list[rowIndex])})
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <h6>Avg. Pairwise Cohen's K</h6>
                              <div className="outlier-detection-utils">
                                <label>
                                  Avg. Pairwise Cohen's K threshold (-1 to 1):
                                  <input
                                    type="number"
                                    value={cohenThresholdValues[index]}
                                    step="0.01"
                                    min="-1"
                                    max="1"
                                    onChange={(e) => handleCohenThresholdChange(e, index)}
                                  />
                                </label>
                                <label>
                                  Annotated instances threshold:
                                  <input
                                    type="number"
                                    value={instancesThresholdValues[index]}
                                    step="1"
                                    min="0"
                                    max="1000000"
                                    onChange={(e) => handleInstancesThresholdChange(e, index)}
                                  />
                                </label>
                                <div 
                                  className="update-statistics-btn" 
                                  onClick={() => excludeOutlyingAnnotators(index)}
                                >
                                  Exclude outlying annotators
                                </div>
                              </div>
                              <table 
                                className="avg-cohens-table"
                                ref={el => tableRefs.current[index] = el}
                              >
                                <thead>
                                  <tr>
                                    <th>Annotator ID</th>
                                    <th>Mean Pairwise Cohen's k</th>
                                    <th>Annotated Instances</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.matrix_list.map((row, rowIndex) => {
                                    const validValues = row.filter(value => value !== null);
                                    const mean = validValues.length > 0
                                      ? (validValues.reduce((sum, val) => sum + val, 0) / validValues.length).toFixed(2)
                                      : 'N/A';
                                    const numAnnotatedInstances =  Math.max(...item.annotated_instances_list[rowIndex])
                                    return (
                                      <tr
                                        key={rowIndex}
                                        className={
                                          ((cohenThresholdValues[index] !== null && parseFloat(mean) < cohenThresholdValues[index]) ||
                                            (instancesThresholdValues[index] !== null && parseInt(numAnnotatedInstances) < instancesThresholdValues[index])
                                          )
                                            ? "outlier"
                                            : ""
                                        }
                                      >
                                        <td>{item.annotator_ids[rowIndex]}</td>
                                        <td>{mean}</td>
                                        <td>{numAnnotatedInstances}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                </div>
                <div className="instances-stats">
                  <div className="instances-stats-header">Instances</div>
                  <div className="instances-stats-body">
                    <div className="instances-list">
                      {dataChunkInstances && dataChunkInstances.map((instance, instanceIndex) => (
                        <div
                          className={`instance-card ${selectedInstanceIndex === instanceIndex ? "selected" : ""}`}
                          key={instanceIndex}
                          title={instance.name}
                          onClick={() => {
                            clearInstanceStatsState()
                            setSelectedInstanceId(instance.id);
                            setSelectedInstanceIndex(instanceIndex);
                            fetchInstanceStatistics(instance.id, instanceIndex);
                          }}
                        >
                          {instance.name}
                        </div>
                      ))}
                    </div>
                    <div className="instance-stats-section">
                      {error ? (
                        <div className="message-container">
                          <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                          <p className="message-text-main">An unexpected error occurred.</p> 
                          <p className="message-text">Please try again later.</p>
                        </div>
                      ) :loadingInstanceStatistics ? (
                        <div className="loading-indicator">
                          <div className="spinner"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        <>
                          <div className="instance-general-stats">
                            <div className="stats-block">
                              <div className="stats-block-header">Annotation progress</div>
                              <div className="instance-progress">
                                <div>Progress: {instanceAnnotationProgress !== null ? instanceAnnotationProgress : "?"}%</div>
                                <div className="progress-bar">
                                  <div className="completion-bar" style={{ width: `${instanceAnnotationProgress !== null ? instanceAnnotationProgress : 0}%` }} />
                                </div>
                              </div>
                              <div className="instance-annotators">
                                <div>Annotated by:</div>
                                {instanceFinishedAnnotators.length > 0 && (
                                  <div className="stat-annotators-list">
                                    {instanceFinishedAnnotators.map((annotator) => (
                                      <div 
                                        className={`annotator-icon ${excludedUsers.some(
                                          ([id, username]) => id === annotator.id && username === annotator.username
                                        ) ? 'excluded' : ''}`}                              
                                        key={annotator.user_id} 
                                        title={annotator.username}
                                      >
                                        {annotator.username[0]}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div className="instance-annotators">
                                <div>Pending:</div>
                                <div className="stat-annotators-list">
                                  {instancePendingAnnotators && instancePendingAnnotators.map((annotator) => (
                                    <div className="annotator-icon" key={annotator.user_id} title={annotator.username}>
                                      {annotator.username[0]}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="stats-block">
                              <div className="stats-block-header">Annotation time</div>
                              <div>Avg. annotation time: {instanceAvgAnnotationTime !== null ? instanceAvgAnnotationTime : "?"} sec.</div>
                              <div>Std. annotation time: {instanceStdAnnotationTime !== null ? instanceStdAnnotationTime : "?"} sec.</div>
                            </div>
                          </div>
                          {["RectangleLabels", "PolygonLabels"].includes(taskType) && (
                            <div className="ground-truth-section">
                              <div className="ground-truth-header">Ground Truth</div>
                              <div className="ground-truth-bar">
                                <div className="dropdown">
                                  <label htmlFor="distance-metric">Choose a metric: </label>
                                  <select
                                    id="distance-metric"
                                    value={selectedSimilarityMetric}
                                    onChange={handleSimilarityMetricChange}
                                  >
                                    <option value="Distance metric" disabled>
                                      Distance metric
                                    </option>
                                    <option value="IoU">IoU</option>
                                    <option value="GIoU">GIoU</option>
                                  </select>
                                </div>
                                <div className="threshold-value">
                                  <label htmlFor="distance-metric">Type a threshold value: </label>
                                  <input
                                    id="threshold-value"
                                    type="text"
                                    value={selectedSimilarityThreshold}
                                    onChange={handleThresholdValueChange}
                                    onBlur={handleThresholdBlur}
                                    placeholder="Values between 0 and 1"
                                    title="Values between 0 and 1"
                                    className="text-input"
                                  />
                                </div>
                              </div>
                              <div 
                                id="label-studio" 
                                className={configureGroundTruthStyle()}
                              ></div>
                            </div>
                          )}
                          {["ValenceArousal", "AdversarialDetection"].includes(taskType) && (
                            <div className="annotation-charts">
                              {instanceAnnotationsCharts.length > 0 ? (
                                <img
                                  className="annotation-chart"
                                  alt={`Chart ${currentChartIndex}`}
                                  src={`data:image/png;base64, ${instanceAnnotationsCharts[currentChartIndex]}`}
                                />
                              ) : (
                                <div className="not-available-container">
                                  <img src={naIcon} className="na-icon" alt="Not Available" />
                                  <span className="na-text">No available charts</span>
                                </div>
                              )}
                              <div className="chart-controls">
                                <button onClick={viewPrevChart} disabled={instanceAnnotationsCharts.length <= 1 || currentChartIndex === 0}>
                                  Previous
                                </button>
                                <button onClick={viewNextChart} disabled={instanceAnnotationsCharts.length <= 1 || currentChartIndex === instanceAnnotationsCharts.length - 1}>
                                  Next
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="modal-footer statistics-footer">
          <button
            className="submission-footer-btn"
            onClick={exportStats}
          >
            Export Statistics
          </button>
        </div>
      </div>
    </>
  );
};

export default DataChunkStatisticsModal;
