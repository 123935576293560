import React, { useState, useEffect } from "react";
import filterIcon from "../../../Images/filter-dark.png";
import sortIcon from "../../../Images/sort-dark.png";
import sortDownIcon from "../../../Images/sort-down-dark.png";
import AnnotatorsIcon from "../../../Images/annotators.png";
import DownloadIcon from "../../../Images/download-icon.png";

import "../UtilsBar.css";
import "./ProjectInstancesUtilsBar.css";

function AdminProjectInstancesUtilsBar({ projectStatus, setOpenFiltersModal, setOpenAssignmentsModal, setOpenProjectStatusModal, exportProjectAnnotations}) {
  const [selectedSortOption, setSelectedSortOption] = useState('Sort By');
  const [isActive, setIsActive] = useState(projectStatus==="Active");

  const handleSortOptionChange = (option) => {
    setSelectedSortOption(option);
  };

  useEffect(() => {
    setIsActive(projectStatus === "Active");
  }, [projectStatus]);


  return (
      <div className="utils-container">
        <div className="utils-section">
          {/* <div className="dropdown">
            <button
              className="util-btn dropdown-toggle"
              type="button"
              id="SortByDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p className="sort-btn-text">{selectedSortOption}</p>
              <img className="main-sort-icon" src={sortDownIcon} alt="SortDownIcon" />
              <img src={sortIcon} className="second-sort-icon" alt="SortIcon" />
            </button>
            <div className="dropdown-menu" aria-labelledby="SortByDropdown">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => handleSortOptionChange('Alphabetically')}
              >
                Alphabetically
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => handleSortOptionChange('Date')}
              >
                Date
              </button>
            </div>
          </div>

          <button className="util-btn" onClick={() => setOpenFiltersModal()}>
            <img src={filterIcon} className="filter-btn-icon" alt="filterIcon" />
          </button>
          <input type="text" placeholder="Search.." className="util-searchbar" /> */}
        </div>
        <div className="main-utils-section">
          <div className="project-status-switch-container">
            <span className="label">Status:</span>
            {
              (projectStatus==="Complete") ? (
                <div className="project-status-circle" title={projectStatus}/>
              ) : (
              <div 
                className={`status-switch ${isActive ? 'active' : ''}`} 
                onClick={() => setOpenProjectStatusModal(true)} 
                title={projectStatus}
              >
                <div className="slider"></div>
              </div>
              )
            }
          </div>
          <button
            className="main-utils-btn"
            title="Assign Annotators"
            onClick={() => setOpenAssignmentsModal(true)}
          >
            <img src={AnnotatorsIcon} alt="AnnotatorsIcon" />
          </button>
          <button
            className="main-utils-btn"
            title="Export Annotations"
            onClick={() => exportProjectAnnotations()}
          >
            <img src={DownloadIcon} alt="DownloadIcon" />
          </button>
        </div>
      </div>
  );
}

export default AdminProjectInstancesUtilsBar;
