import axios from 'axios';

const baseURL = 'https://audio-tool-api.annotation.scify.org'
const timeout = 10000; // Request timeout in milliseconds

const api = axios.create({
    baseURL,
    timeout,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default api;