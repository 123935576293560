import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import api from '../../../axiosApi/api';

import AnnotatorProjectInstancesUtilsBar from '../../UtilsBar/ProjectInstancesUtilsBar/AnnotatorProjectInstancesUtilsBar';
import "./../Dashboard.css";
import "./AnnotatorProjectInstancesDashboard.css";
import naIcon from "../../../Images/naNew.png";
import DownArrowIcon from "../../../Images/down-arrow.png";
import AnnotatorsIcon from "../../../Images/annotators.png";
import DownloadIcon from "../../../Images/download-icon.png";
import RightArrowIcon from "../../../Images/right-arrow.png";
import ErrorIcon from "../../../Images/error-icon.png"


function AnnotatorProjectInstancesDashboard() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    taskType: taskTypeParam, 
    taskView: taskViewParam, 
    annotationType: annotationTypeParam, 
    projectModality: projectModalityParam, 
    projectLabels: projectLabelsParam, 
    projectStatus: projectStatusParam
  } = location.state || {};
  const currentPath = location.pathname;

  const [projectDetails, setProjectDetails] = useState({
    taskType: taskTypeParam,
    taskView: taskViewParam,
    annotationType: annotationTypeParam,
    projectModality: projectModalityParam,
    projectLabels: projectLabelsParam,
    projectStatus: projectStatusParam
  });

  const [fetchedData, setFetchedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [instancesLoading, setInstancesLoading] = useState(true);
  const [expandedStates, setExpandedStates] = useState([]);
  const [error, setError] = useState(false);


  const toggleExpanded = (index) => {
    setExpandedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  // Method to fetch data chunks of project from the database
  const fetchAnnotatedInstancesPerDataChunk = async () => {
    try {
      const res = await api.get(`/api/projects/${projectId}/annotatedInstances`, {
        headers: {
          "Authorization": 'Bearer ' + localStorage.getItem('jwtToken')
        }
      });
      const annotatedInstances = res.data.instancesPerDataChunk;
      setError(false);
      setFetchedData(annotatedInstances);
      setFilteredData(annotatedInstances);
      setExpandedStates(new Array(res.data.instancesPerDataChunk.length).fill(false));
      setInstancesLoading(false);
    } catch (error) {
      setError(true);
      setInstancesLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnotatedInstancesPerDataChunk();
  }, []);


  return (
    <>
      <section className="dashboard annotated-instances-dashboard">
        <h2>Annotated Instances</h2>
        {/* <AnnotatorProjectInstancesUtilsBar
          filteredData={filteredData}
          projectStatus={projectStatus}
          setFilteredData={setFilteredData}
          setOpenFiltersModal={() => setOpenFiltersModal(true)}
          annotateInstances={() => setStartAnnotations(true)}
        /> */}
        <div className="annotated-instances-container">
          {error ? (
              <div className="message-container">
                <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                <p className="message-text-main">An unexpected error occurred.</p> 
                <p className="message-text">Please try again later.</p>
              </div>
            ) : instancesLoading ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
                <span>Loading...</span>
              </div>
            ) : ( fetchedData.length > 0 ? (
              <div className="project-instances-list">
                {fetchedData.map((dataChunk, dataChunkIndex) => (
                  <div className="data-chunk-sublist" key={dataChunk.id}>
                    <div className="data-chunk-card">
                      <div className="data-chunk-card-title"> Data Chunk {dataChunkIndex + 1} </div>
                      <div className="data-chunk-card-utils">
                        <button 
                          className="annotate-btn"
                          title="Annotate" 
                          disabled={(projectDetails.projectStatus!=="Active")}
                        >
                          <Link
                            className="annotation-btn"
                            to={`${currentPath}/data-chunks/${dataChunk.id}/annotate`}
                            disabled={(projectDetails.projectStatus!=="Active")}
                            state={{
                            }}
                          >
                            Annotate
                          </Link>
                        </button>
                        <div className="expand-btn" onClick={() => toggleExpanded(dataChunkIndex)}>
                          <img src={expandedStates[dataChunkIndex] ? DownArrowIcon : RightArrowIcon} alt="Expand" />
                        </div>
                      </div>
                    </div>
                    {expandedStates[dataChunkIndex] && (
                      <div className="instances-cards-header">
                        <div className="fields-section">
                          <span className="header-field">Name</span>
                          <span className="header-field">File Type</span>
                          <span className="header-field">File Size</span>
                        </div>
                        <div className="empty-section"></div>
                      </div>
                    )}
                    {expandedStates[dataChunkIndex] && (
                      dataChunk.instances.map((instance, instanceIndex) => (
                        <div className="instance-card" key={instance.id}>
                          <div className="item-fields-section">
                            <span className="item-field instance-card-title">{instance.name}</span>
                            <span className="item-field">{instance.fileType}</span>
                            <span className="item-field">{instance.fileSize}</span>
                          </div>
                          <div className="item-utils-section">
                            <button className="view-btn">
                              <Link
                                className="btn-link"
                                to={`${currentPath}/data-chunks/${dataChunk.id}/instances/${instance.id}`}
                                state={{
                                  projectId: projectId,
                                  dataChunkId: dataChunk.id,
                                  instanceId: instance.id,
                                  instanceParentDatasetName: instance.parentDataset.name,
                                  instanceName: instance.name,
                                  instanceFileType: instance.fileType,
                                  instanceFileSize: instance.fileSize,
                                  instanceRelativePath: instance.relativePath
                                }}
                              >
                                View <span>&gt;</span>
                              </Link>
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="message-container">
                <img src={naIcon} className="message-icon" alt="Not Available" />
                <span className="message-text-main">No submitted annotations yet.</span>
              </div>
            )
          )}
        </div>
      </section>
    </>
  );
}

export default AnnotatorProjectInstancesDashboard;
