import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import AnnotatorProjectsDashboard from "../../components/Dashboards/ProjectsDashboard/AnnotatorProjectsDashboard";
import AnnotatorProjectInstancesDashboard from "../../components/Dashboards/ProjectInstancesDashboard/AnnotatorProjectInstancesDashboard";
import InstanceDashboard from "../../components/Dashboards/InstanceDashboard/InstanceDashboard";
import AnnotationDashboard from '../../components/Dashboards/AnnotationDashboard/AnnotationDashboard';

import '../Styles/DashboardPage.css';


function AnnotatorDashboardPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/annotator/dashboard") {
      navigate("/annotator/dashboard/projects");
    }
  }, [navigate]);

  return (
    <div className="dashboard-page">
      <div className="dashboard-section">
        <Routes>
          <Route path="/projects" element={<AnnotatorProjectsDashboard />} />
          <Route path="/projects/:projectId" element={<AnnotatorProjectInstancesDashboard />} />
          <Route path="/projects/:projectId/data-chunks/:dataChunkId/annotate" element={<AnnotationDashboard />} />
          <Route path="/projects/:projectId/data-chunks/:dataChunkId/instances/:instanceId" element={<InstanceDashboard />} />
        </Routes>
      </div>
    </div>
  );
}

export default AnnotatorDashboardPage;