import React, { useState, useEffect } from "react";
import { initializeLabelStudio } from "../GroundTruthLabelStudioConfig.js";
import JSZip from 'jszip';

import api from '../../../../axiosApi/api';
import BackgroundOverlay from "../../../BackgroundOverlay/BackgroundOverlay";

import xIcon from "../../../../Images/x-icon.png"
import naIcon from "../../../../Images/naNew.png";

import "../../Modals.css"
import "../StatisticsModal.css"
import "./InstanceStatisticsModal.css"


const InstanceStatisticsModal = ({ taskType, dataChunkId, instanceId, instanceName, instanceRelativePath, 
                                    taskView, annotationType, projectModality, projectLabels, onClose }) => {
 
  const [loadingStatistics, setLoadingStatistics] = useState(true)

  const [annotationProgress, setAnnotationProgress] = useState(null)
  const [finishedAnnotators, setFinishedAnnotators] = useState([])
  const [pendingAnnotators, setPendingAnnotators] = useState([])
  const [avgAnnotationTime, setAvgAnnotationTime] = useState(null)
  const [stdAnnotationTime, setStdAnnotationTime] = useState(null)
  const [annotationsCharts, setAnnotationsCharts] = useState([])
  const [groundTruthAnnotations, setGroundTruthAnnotations] = useState([]);
  const [selectedSimilarityMetric, setSelectedSimilarityMetric] = useState('Similarity metric');
  const [selectedSimilarityThreshold, setSelectedSimilarityThreshold] = useState('0.5');
  const [JSONFile, setJSONFile] = useState()

  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch instance statistics when page loads
  useEffect(() => {
    fetchInstanceStatistics();
  }, []);
  
  // Handle view of instance agreement charts
  const nextChart = () => {
    if(annotationsCharts !== null)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % annotationsCharts.length);
  };

  const prevChart = () => {
    if(annotationsCharts !== null)
      setCurrentIndex((prevIndex) => (prevIndex - 1 + annotationsCharts.length) % annotationsCharts.length);
  };

  // Fetch instance statistics
  const fetchInstanceStatistics = async () => {
    try {
      setLoadingStatistics(true);

      let params_dict = {
        projectType: taskType,
        annotationType: annotationType
      };
      
      if (["RectangleLabels", "PolygonLabels"].includes(taskType)) {
        params_dict["groundTruthSettings"] = {
          similarity_metric: selectedSimilarityMetric === "Similarity metric" ? "IoU" : selectedSimilarityMetric,
          similarity_threshold: selectedSimilarityThreshold
        };
      }

      const res = await api.get(`/api/data-chunks/${dataChunkId}/instances/${instanceId}/stats`, {
        headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwtToken') },
        params: params_dict
      });

      setAnnotationProgress(res.data.annotationProgress);
      setFinishedAnnotators(res.data.annotators.finishedAnnotators);
      setPendingAnnotators(res.data.annotators.pendingAnnotators);
      setAvgAnnotationTime(res.data.avgAnnotationTime);
      setStdAnnotationTime(res.data.stdAnnotationTime);


      if (taskType === "ValenceArousal" || taskType === "AdversarialDetection") {
        setAnnotationsCharts(res.data.agreementCharts);
      } else if (taskType === "RectangleLabels" || taskType === "PolygonLabels") {
        setGroundTruthAnnotations(res.data.groundTruthAnnotations);
        initializeLabelStudio(
          taskView,
          projectModality, 
          projectLabels, 
          annotationType,
          instanceRelativePath,
          res.data.groundTruthAnnotations
        );
      }
      setJSONFile(res.data.jsonFile);
      setLoadingStatistics(false);
    } catch (error) {
      console.error("Error fetching instance statistics:", error);
      setLoadingStatistics(false);
    }
  };

  // Configure Ground Truth style
  const configureStyle = () => {
    if (taskType === "PolygonLabels"){
      import("../LabelStudioStyles/GroundTruthPolygonLabels.css");
      return("ground-truth-polygon-labels")
    } 
    else if (taskType === "RectangleLabels"){
      import("../LabelStudioStyles/GroundTruthRectangleLabels.css");
      return("ground-truth-rectangle-labels")
    } 
    else if (taskType === "ImageClassification"){
      import("../LabelStudioStyles/GroundTruthImageClassification.css");
      return("ground-truth-image-classification");
    } 
    else if (taskType === "ImageCaptioning"){
      import("../LabelStudioStyles/GroundTruthImageCaptioning.css");
      return("ground-truth-image-captioning");
    } 
    else if (taskType === "AudioCaptioning"){
      import("../LabelStudioStyles/GroundTruthAudioCaptioning.css");
      return("ground-truth-audio-captioning");
    } 
    else if (taskType === "AudioClassification"){
      import("../LabelStudioStyles/GroundTruthAudioClassification.css");
      return("ground-truth-audio-classification");
    } 
    else if (taskType === "SegmentsAudioClassification"){
      import("../LabelStudioStyles/GroundTruthSegmentsAudioClassification.css");
      return("ground-truth-segments-audio-classifiction");
    } 
    else if (taskType === "SegmentsAudioCaptioning"){
      import("../LabelStudioStyles/GroundTruthSegmentsAudioCaptioning.css");
      return("ground-truth-segments-audio-captioning");
    } 
    else if (taskType === "ValenceArousal"){
      import("../LabelStudioStyles/GroundTruthValenceArousal.css");
      return("ground-truth-valence-arousal");
    } 
    else if (taskType === "AdversarialDetection"){
      import("../LabelStudioStyles/GroundTruthAdversarialDetection.css");
      return("ground-truth-adversarial-detection");
    } 
  }

  // Export statistics zip
  const exportStats = async () => {
    if (!JSONFile && annotationsCharts.length === 0) {
      console.error("No JSON file or charts available to export.");
      return;
    }
  
    try {
      // Decode the base64 JSON file
      const jsonFileContent = atob(JSONFile);
  
      // Parse the JSON content to ensure it's valid
      const jsonData = JSON.parse(jsonFileContent);
  
      // Convert the JSON object back to a string
      const jsonString = JSON.stringify(jsonData, null, 2);
  
      // Create a Blob from the UTF-8 encoded JSON content
      const utf8Encoder = new TextEncoder();
      const utf8Content = utf8Encoder.encode(jsonString);
  
      // Initialize a new JSZip instance
      const zip = new JSZip();
  
      // Add the JSON content to the zip file
      zip.file(`${instanceName}_statistics.json`, utf8Content);
  
      // Add charts to the zip file
      annotationsCharts.forEach((chartBase64, index) => {
        const chartBinary = Uint8Array.from(atob(chartBase64), c => c.charCodeAt(0));
        zip.file(`chart_${index + 1}.png`, chartBinary);
      });
  
      // Generate the zip file content as a blob
      const zipContent = await zip.generateAsync({ type: "blob" });
  
      // Create a URL for the Blob
      const url = URL.createObjectURL(zipContent);
  
      // Create an anchor element and trigger a download
      const a = document.createElement("a");
      a.href = url;
      a.download = `statistics.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Revoke the object URL after the download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting JSON file or charts:", error);
    }
  };


  return (
    <>
      <BackgroundOverlay></BackgroundOverlay>
      <div className="modal statistics-modal">
        <div className="modal-header">
          <h3>Statistics</h3>
          <img src={xIcon} className="x-icon" onClick={onClose} alt = "xIcon"></img>
        </div>
        <div className="modal-body statistics-body">
          
            {loadingStatistics ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
                <span>Loading...</span>
              </div>
            ) : (
              <div className="instance-statistics-content">
                <div className="instance-name"> {instanceName}</div>
                <div className="instance-stats">
                  <div className="instance-general-stats">
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation progress</div>
                      <div className="stats-block-body">
                        <div>Progress: {annotationProgress ? annotationProgress : "?"}%</div>
                        <div className="progress-bar">
                          <div className="completion-bar" style={{width: `${annotationProgress ? annotationProgress : 0}%`}}/>
                        </div>
                        <div>
                            <div>Annotated by:</div>
                            {finishedAnnotators.length > 0 &&
                              <div className="annotators-list">
                                {finishedAnnotators.map((annotator, annotatorIndex) => (
                                  <div className="annotator-icon" key={annotator.user_id} title={annotator.username}>
                                    {annotator.username[0]}
                                  </div>
                                ))}
                              </div>
                            }
                        </div>
                        <div>
                          <div>Pending:</div>
                          {pendingAnnotators.length > 0 &&
                            <div className="annotators-list">
                              {pendingAnnotators.map((annotator, annotatorIndex) => (
                                <div className="annotator-icon" key={annotator.user_id} title={annotator.username}>
                                  {annotator.username[0]}
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="stats-block">
                      <div className="stats-block-header">Annotation time</div>
                      <div className="stats-block-body">
                        <div>Avg. annotation time: {avgAnnotationTime ? avgAnnotationTime : "?"} sec.</div>
                        <div>Std. annotation time: {stdAnnotationTime ? stdAnnotationTime : "?" } sec.</div>
                      </div>
                    </div>
                  </div>
                  {["RectangleLabels", "PolygonLabels"].includes(taskType) && (
                      <div 
                        id="label-studio" 
                        className={configureStyle()}
                        //style={{ display: loadInstanceStatistics ? 'none' : 'block' }}
                      ></div>
                    )}
                    {["ValenceArousal", "AdversarialDetection"].includes(taskType) && (
                      <div className="annotation-charts">
                        {annotationsCharts.length > 0 ? (
                          <div className="annotation-chart">
                            <img
                              alt={`Chart ${currentIndex}`}
                              src={`data:image/png;base64, ${annotationsCharts[currentIndex]}`}
                            />
                          </div> 
                        ) : (
                          <div className="not-available-container">
                            <img src={naIcon} className="na-icon" alt="Not Available" />
                            <span className="na-text">No available charts</span>
                          </div>
                        )}
                        <div className="chart-controls">
                          <button onClick={prevChart} disabled={annotationsCharts.length <= 1 || currentIndex === 0}>
                            Previous
                          </button>
                          <button onClick={nextChart} disabled={annotationsCharts.length <= 1 || currentIndex === annotationsCharts.length - 1}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
        </div>
        <div className="modal-footer statistics-footer">
          <button 
            disabled={false}
            className="submission-footer-btn"
            onClick={exportStats}
          > 
            Export statistics
          </button>
        </div>
      </div>
    </>
  )
}
export default InstanceStatisticsModal