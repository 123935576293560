import React, { useState, useEffect } from "react";
import xIcon from "../../../Images/x-icon.png";
import uploadIcon from "../../../Images/upload.png";
import "../Modals.css";
import "./UploadDatasetModal.css";
import BackgroundOverlay from '../../BackgroundOverlay/BackgroundOverlay';

const UploadDatasetModal = ({ open, onClose, uploadDataset }) => {
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());

  useEffect(() => {
    return () => controller.abort();
  }, [controller]);

  if (!open) return null;

  async function parseFiles(directoryHandle, relativePath, signal) {
    try {
      const entries = await directoryHandle.values({ signal });
      for await (const entry of entries) {
        if (signal.aborted) {
          console.log('Operation aborted');
          return;
        }
        const currentRelativePath = relativePath + "/" + entry.name;
        if (entry.kind === 'directory') {
          await parseFiles(entry, currentRelativePath, signal);
        } else {
          const dotSplitTokens = entry.name.split('.');
          const fileName = dotSplitTokens.length === 2 ? dotSplitTokens[0] : dotSplitTokens.slice(0, -1).join(".");
          const fileType = dotSplitTokens.length === 2 ? dotSplitTokens[1] : dotSplitTokens[dotSplitTokens.length - 1];
          const slashSplitTokens = currentRelativePath.split('/');
          const parentPath = slashSplitTokens.length === 2 ? 'root' : slashSplitTokens.slice(0, -1).join("/");
          const metadata = await entry.getFile();
          const fileSize = metadata.size;
          const instance = {
            "relativePath": currentRelativePath,
            "fileName": fileName,
            "fileType": fileType,
            "fileSize": fileSize,
            "parentPath": parentPath
          };
          setSelectedInstances(prevInstances => [...prevInstances, instance]);
        }
      }
    } catch (error) {
      console.error('Error handling folder:', error);
    }
  }

  async function selectFolder() {
    try {
      const newController = new AbortController();
      setController(newController);
      const directoryHandle = await window.showDirectoryPicker();
      setFolderName(directoryHandle.name);
      setIsLoading(true);
      await parseFiles(directoryHandle, "", newController.signal);
      setIsLoading(false);
    } catch (error) {
      console.error('Error selecting folder:', error);
    }
  }

  const handleUpload = async () => {
    try {
      await uploadDataset(folderName, selectedInstances);
      clearState();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const clearState = () => {
    setSelectedInstances([]);
    setFolderName("");
  };

  const onCloseClick = () => {
    clearState();
    onClose();
  };

  return (
    <>
      <BackgroundOverlay />
      <div className="dataset-upload-modal">
        <div className="datasets-modal-header">
          <h3>Upload Dataset</h3>
          <img src={xIcon} className="x-icon" onClick={onCloseClick} alt="xIcon" />
        </div>
        <div className="datasets-modal-body">
          {isLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : selectedInstances.length === 0 ? (
            <div className="instances-selection-section">
              <div className="instances-selection-body">
                <img src={uploadIcon} alt="uploadIcon" onClick={() => selectFolder()} />
                <span>Select a folder of files to upload.</span>
              </div>
            </div>
          ) : (
            <div className="upload-finalization-section">
              <div className='selected-instances-container'>
                <div className="selected-instances-header">
                  <span> <h6>Files to Upload</h6> ({selectedInstances.length} files) </span>
                  <button className="remove-instances-btn" onClick={() => setSelectedInstances([])}>
                    Clear
                  </button>
                </div>
                <div className="selected-instances-list">
                  <div className="instances-list-header">
                    <div className="header-field">File Name</div>
                    <div className="header-field">File Type</div>
                    <div className="header-field">Size</div>
                    <div className="header-field">Parent Path</div>
                  </div>
                  {selectedInstances.map(instance => (
                    <div key={instance.relativePath} className="instances-list-item">
                      <div className="instance-card-field">
                        <div className="field-text">{instance.fileName}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text">{instance.fileType}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text">{instance.fileSize}</div>
                      </div>
                      <div className="instance-card-field">
                        <div className="field-text">{instance.parentPath}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='folder-name-container'>
                <h6>Folder name</h6>
                <input
                  type="text"
                  id="folder-name-input"
                  placeholder={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  value={folderName}
                  aria-describedby="uidnote"
                  autoComplete="False"
                />
              </div>
            </div>
          )}
        </div>
        <div className="datasets-modal-footer">
          <button
            className="submission-footer-btn"
            disabled={selectedInstances.length === 0 || isLoading}
            onClick={() => handleUpload()}
          >Upload</button>
        </div>
      </div>
    </>
  );
};

export default UploadDatasetModal;
