import React from "react";
import "./ProjectDetailsStage.css";

const ProjectDetailsStage = ({
  projectName,
  setProjectName,
  projectDescription,
  setProjectDescription,
}) => {
  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleProjectDescriptionChange = (e) => {
    setProjectDescription(e.target.value);
  };

  return (
    <div className="project-details-container">
      <h5>Project Details</h5>
      <div className="project-details-group">
        <label htmlFor="project-name">
          <h6>Project Name</h6>
        </label>
        <input
          type="text"
          id="project-name"
          placeholder="Enter project name"
          onChange={handleProjectNameChange}
          value={projectName}
          aria-describedby="uidnote"
          autoComplete="false"
        />
      </div>
      <div className="project-details-group">
        <label htmlFor="project-description">
          <h6>Project Description</h6>
        </label>
        <textarea
          name="paragraph_text"
          cols="50"
          rows="10"
          placeholder="Enter project description"
          id="project-description"
          value={projectDescription}
          onChange={handleProjectDescriptionChange}
        ></textarea>
      </div>
    </div>
  );
};

export default ProjectDetailsStage;
