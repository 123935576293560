import React, { useState, useEffect } from "react";
import filterIcon from "../../../Images/filter-dark.png";
import sortIcon from "../../../Images/sort-dark.png";
import sortDownIcon from "../../../Images/sort-down-dark.png";
import "../UtilsBar.css";

function AnnotatorProjectsUtilsBar({ filteredData, setFilteredData, openFiltersModal}) {
  const [selectedSortOption, setSelectedSortOption] = useState('Sort By');

  useEffect(() => {
    let sortedCards = [];

    if (selectedSortOption === 'Date' || selectedSortOption === 'Sort By') {
      sortedCards = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.creation_date);
        const dateB = new Date(b.creation_date);
        return dateA - dateB;
      });
    }
    if (selectedSortOption === "Alphabetically") {
      sortedCards = [...filteredData].sort((a, b) => a.project_name.localeCompare(b.project_name));
    }

    setFilteredData(sortedCards);
  }, [selectedSortOption]);

  return (
    <div className="utils-container">
      <div className="utils-section">
        {/* <div className="dropdown">
          <button
            className="util-btn dropdown-toggle"
            type="button"
            id="SortByDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p className="sort-btn-text">{selectedSortOption}</p>
            <img className="main-sort-icon" src={sortDownIcon} alt="SortDownIcon" />
            <img src={sortIcon} className="second-sort-icon" alt="SortIcon" />
          </button>
          <div className="dropdown-menu" aria-labelledby="SortByDropdown">
            <button
              className="dropdown-item"
              type="button"
              onClick={() => setSelectedSortOption('Alphabetically')}
            >
              Alphabetically
            </button>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => setSelectedSortOption('Date')}
            >
              Date
            </button>
          </div>
        </div>

        <button className="util-btn" onClick={() => openFiltersModal()}>
          <img src={filterIcon} className="filter-btn-icon" alt="filterIcon" />
        </button>
        <input type="text" placeholder="Search.." className="util-searchbar" /> */}
      </div>
    </div>
  );
}

export default AnnotatorProjectsUtilsBar;
