import React from 'react';
import './NotFoundPage.css';
import NotFoundIcon from '../../Images/not-found.png'

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <img src={NotFoundIcon} alt="not-found" className='not-found-img'/>
      <span className='main-msg-text'>Page not found</span>
      <span className='secondary-msg-text'>We're sorry, the page you requested could not be found</span>
    </div>
  )
}


export default NotFoundPage;