import React, { useState, useEffect } from "react";
import { Sketch } from '@uiw/react-color';
import palletteIcon from "../../../../../Images/pallette.png";
import "./LabelSelectionStage.css";

const LabelSelectionStage = ({ labels, setLabels }) => {
  const [visibleColorPicker, setVisibleColorPicker] = useState(false);
  const [enabledAddLabelButton, setEnabledAddLabelButton] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelColor, setLabelColor] = useState("");

  useEffect(() => {
    setEnabledAddLabelButton(labelName !== "" && labelColor !== "");
  }, [labelName, labelColor]);

  const toggleColorPicker = () => {
    setVisibleColorPicker(!visibleColorPicker);
  };

  const addLabel = () => {
    const newLabel = { name: labelName, color: labelColor };
    setLabels(prevLabels => [...prevLabels, newLabel]);
    setLabelName("");
    setLabelColor("");
  };

  const removeLabel = (index) => {
    const updatedLabels = labels.filter((_, idx) => idx !== index);
    setLabels(updatedLabels);
  };

  return (
    <div className="label-selection-container">
      <div className="label-utils-section">
        <h5>Select Labels</h5>
        <div className="label-utils">
          <input
            type="text"
            id="label-name-input"
            placeholder="Enter label name"
            autoComplete="on"
            onChange={(e) => setLabelName(e.target.value)}
            value={labelName}
            aria-describedby="uidnote"
          />
          <button className="color-button" onClick={toggleColorPicker}>
            <img src={palletteIcon} alt="palletteIcon" />
          </button>
          {visibleColorPicker && (
            <Sketch
              className="color-picker"
              color={labelColor}
              onChange={(color) => setLabelColor(color.hex)}
            />
          )}
          <button
            className="add-button"
            onClick={addLabel}
            disabled={!enabledAddLabelButton}
          >
            Add
          </button>
        </div>
      </div>
      <div className="labels-list-section">
        {labels.length === 0 ? (
          <span className="no-labels-message">No available labels yet.</span>
        ) : (
          <div className="labels-list">
            {labels.map((label, index) => (
              <div className="labels-list-item" key={index}>
                <div className="label-info">
                  <span>{label.name}</span>
                  <div className="label-color-box" style={{ backgroundColor: label.color }}></div>
                </div>
                <button className="remove-label-button" onClick={() => removeLabel(index)}>
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelSelectionStage;
