import { React, useState, useEffect } from "react";
import api from '../../../../axiosApi/api';

import BackgroundOverlay from "../../../BackgroundOverlay/BackgroundOverlay";
import xIcon from "../../../../Images/x-icon.png"
import naIcon from "../../../../Images/na.png"
import ErrorIcon from "../../../../Images/error-icon.png";

import "../../Modals.css"
import "../AnnotatorsModal.css"


const ProjectAnnotatorsModal = ({ selectedProjectId, dataChunks, updateAssignments, onClose }) => {

  const [error, setError] = useState(false)
  const [selectedDataChunk, setSelectedDataChunk] = useState()
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [renderedUsersCategory, setRenderedUsersCategory] = useState("assigned")
  const [allUsers, setAllUsers] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [notAssignedUsers, setNotAssignedUsers] = useState([])
  const [pendingAssignments, setPendingAssignments] = useState([])
  const [pendingRemovals, setPendingRemovals] = useState([])

  const filterAssignedUsersByDataChunkAccessibility = () => {
    const filteredUsers = allUsers.filter(user => (user.accessibleDataChunks.includes(selectedDataChunk)));
    setAssignedUsers(filteredUsers)
  }

  const filterNotAssignedUsersByDataChunkAccessibility = () => {
    const filteredUsers = allUsers.filter(user => (!user.accessibleDataChunks.includes(selectedDataChunk)));
    setNotAssignedUsers(filteredUsers)
  }

  // Handle the assignment (or removal) button click for each user card
  const handleAssignmentButtonClick = (userId) => {
    const desiredItem = {
        userId: userId,
        projectId: selectedProjectId,
        dataChunkId: selectedDataChunk,
    };

    if (renderedUsersCategory === "assigned") {
        const exists = pendingRemovals.some(item =>
            item.userId === desiredItem.userId &&
            item.projectId === desiredItem.projectId &&
            item.dataChunkId === desiredItem.dataChunkId
        );

        if (exists) {
            setPendingRemovals(pendingRemovals.filter(removal =>
                removal.userId !== desiredItem.userId ||
                removal.projectId !== desiredItem.projectId ||
                removal.dataChunkId !== desiredItem.dataChunkId
            ));
        } else {
            setPendingRemovals([...pendingRemovals, desiredItem]);
        }
    } else if (renderedUsersCategory === "not-assigned") {
      const exists = pendingAssignments.some(item =>
          item.userId === desiredItem.userId &&
          item.projectId === desiredItem.projectId &&
          item.dataChunkId === desiredItem.dataChunkId
      );

      if (exists) {
          setPendingAssignments(pendingAssignments.filter(assignment =>
              assignment.userId !== desiredItem.userId ||
              assignment.projectId !== desiredItem.projectId ||
              assignment.dataChunkId !== desiredItem.dataChunkId
          ));
      } else {
          setPendingAssignments([...pendingAssignments, desiredItem]);
      }
    }
  }


  const isPending = (userId) => {
    const objectToCheck = {
        userId: userId,
        projectId: selectedProjectId,
        dataChunkId: selectedDataChunk
    }

    if (selectedDataChunk !== undefined) {
      if (renderedUsersCategory === "assigned") {
        const exists = pendingRemovals.some(item =>
          item.userId === objectToCheck.userId &&
          item.projectId === objectToCheck.projectId &&
          item.dataChunkId === objectToCheck.dataChunkId
        );
        return exists;
      } else if (renderedUsersCategory === "not-assigned") {
        const exists = pendingAssignments.some(item =>
          item.userId === objectToCheck.userId &&
          item.projectId === objectToCheck.projectId &&
          item.dataChunkId === objectToCheck.dataChunkId
        );
        return exists;
      }
    }
    return false; 
  }

  // Submit the updated user assignments
  const handleAssignmentsUpdate = () => {
    let updatedUsersData = []

    pendingAssignments.map((assignment) => {
      let updatedUserDocument = {
        userId:assignment.userId,
        updatedData: {
          accessibleProjects: {
            "additions": [
              {
                "projectId": assignment.projectId
              }
            ]
          },
          accessibleDataChunks: {
            "additions": [
              {
                "dataChunkId": assignment.dataChunkId
              }
            ]
          }
        }
      };
      updatedUsersData.push(updatedUserDocument);
    });

    pendingRemovals.map((removal) => {
      let updatedUserDocument = {
        userId:removal.userId,
        updatedData: {
          accessible_projects: {
            "removals": [
              {
                "projectId": removal.projectId
              }
            ]
          },
          accessibleDataChunks: {
            "removals": [
              {
                "dataChunkId": removal.dataChunkId
              }
            ]
          }
        }
      };
      updatedUsersData.push(updatedUserDocument);
    });
    
    updateAssignments(updatedUsersData).then((submissionResult) => {
      if (submissionResult.success) {
        onClose(); 
      } else {
        console.error(submissionResult.error);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    onClose();
  }

  // Clear the state of the project creation modal
  const clearState = () => {
    setLoadingUsers(false)
    setRenderedUsersCategory("assigned")
    setSelectedDataChunk()
    setAllUsers([])
    setAssignedUsers([])
    setNotAssignedUsers([])
    setPendingAssignments([])
    setPendingRemovals([])
  }

  // Close the project creation modal
  const onCloseClick = () => {
    clearState()
    onClose()
  };

  // Fetch available users when modal is rendered
  useEffect(() => {
    setLoadingUsers(true);
    api.get('/api/users', 
      { 
        params: {  
            role: "Regular" 
        },
        headers: {  
            Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
        }
      }
    )
    .then((res) => {
      setError(false);
      const fetchedUsers = res['data']['users']
      setAllUsers(fetchedUsers)
      setLoadingUsers(false);
    })
    .catch((error) => {
      console.log(error)
      setError(true)
      setLoadingUsers(false);
    });
  }, []);

  // Filter users to assigned and not assigned when data chunk selected
  useEffect(() => {
    filterAssignedUsersByDataChunkAccessibility()
    filterNotAssignedUsersByDataChunkAccessibility()
  }, [selectedDataChunk])


  return (
    <>
      <BackgroundOverlay></BackgroundOverlay>
      <div className="assignments-modal">
        <div className="assignments-modal-header">
          <h3>Manage Annotators</h3>
          <img src={xIcon} className="x-icon" onClick={onCloseClick} alt = "xIcon"></img>
        </div>
        <div className="assignments-modal-body">
          <div className="data-chunks-container">
            <h5>Data Chunks</h5>
            <div className="data-chunks-list">
              {dataChunks.map((dataChunk, dataChunkIndex) => (
                <div 
                  key={dataChunkIndex}
                  className={`data-chunk-item ${(selectedDataChunk!=undefined && dataChunk === selectedDataChunk) && 'selected'}`}
                  onClick={() => setSelectedDataChunk(dataChunk)}
                >
                  DC {dataChunkIndex + 1}
                </div>
              ))}
            </div>
          </div>
          <div className="assignments-container">
            <div className="assignments-container-header">
              <div className="user-filters-section">
                <button 
                  className={`user-filter ${renderedUsersCategory === "assigned" ? "selected" : ""}`} 
                  onClick={() => setRenderedUsersCategory("assigned")}
                >
                    Assigned Users
                  </button>
                <div className="vertical-seperator"></div>
                <button 
                  className={`user-filter ${renderedUsersCategory === "not-assigned" ? "selected" : ""}`} 
                  onClick={() => setRenderedUsersCategory("not-assigned")}
                >
                  Not Assigned Users
                </button>
              </div>
            </div>
            <div className="assignments-container-body">
              {error ? (
                <div className="message-container">
                  <img src={ErrorIcon} className="message-icon" alt="Error icon" />
                  <p className="message-text-main">An unexpected error occurred.</p>
                  <p className="message-text">Please try again later.</p>
                </div>
              ) : loadingUsers ? (
                <div className="loading-indicator">
                  <div className="spinner"></div>
                  <span>Loading...</span>
                </div>
              ) : (renderedUsersCategory === "assigned" ? assignedUsers : notAssignedUsers).length === 0 ? (
                <div className="no-users-container">
                  <img src={naIcon} className="na-icon" alt="No Users" />
                  <span className="na-text">Not available users</span>
                </div>
              ) : (
                <div className="users-list">
                  {(renderedUsersCategory === "assigned" ? assignedUsers : notAssignedUsers).map((user) => (
                    <div key={user.id} className="user-card">
                      <span className="card-field card-title" title={user.username}>
                        {user.username}
                      </span>
                      <button
                        className={`assignment-btn ${isPending(user.id) ? 'pending' : ''}`}
                        onClick={() => handleAssignmentButtonClick(user.id)}
                      >
                        {renderedUsersCategory === "assigned" ? "Remove" : "Assign"}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

          </div>
        </div>
        <div className="assignments-modal-footer">
          <button 
            disabled={pendingAssignments.length===0 && pendingRemovals.length===0}
            className="submission-footer-btn"
            onClick={ () => handleAssignmentsUpdate() }
          > 
            Save Changes
          </button>
        </div>
      </div>
    </>
  )
}
export default ProjectAnnotatorsModal
