import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import api from '../../../axiosApi/api';
import { initializeLabelStudio } from "./LabelStudioAnnotationConfig";

import "label-studio/build/static/css/main.css";
import "./../Dashboard.css";
import "./AnnotationDashboard.css";

import taskCompletedIcon from '../../../Images/task-completed.png';
import ErrorIcon from "../../../Images/error-icon.png";


function AnnotationDashboard() {
  const { projectId, dataChunkId } = useParams();
  const [projectModality, setProjectModality] = useState();
  const [projectTaskType, setProjectTaskType] = useState();
  const [projectLabels, setProjectLabels] = useState();
  const [instanceId, setInstanceId] = useState();
  const [previousInstanceId, setPreviousInstanceId] = useState();
  const [instanceName, setInstanceName] = useState();
  const [instanceParentDataset, setInstanceParentDataset] = useState();
  const [instanceRelativePath, setInstanceRelativePath] = useState();
  const [instanceLoading, setInstanceLoading] = useState(true);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState();  
  const [annotationChain, setAnnotationChain] = useState([]);
  const [submissionClicks, setSubmissionClicks] = useState(0);
  const [error, setError] = useState(false);

  const dequeueAnnotationChain = async () => {
    setAnnotationChain(prevChain => {
      const updatedChain = prevChain.slice(1); // Remove the first item from the chain
      if (updatedChain.length > 0) {
        configureAnnotationInstance(updatedChain[0]); // Configure the new first instance
      } else {
        setTaskCompleted(true); // No more instances
      }
      return updatedChain;
    });
  };

  useEffect(() => {
    setInstanceLoading(true);

    // Fetch project details
    api.get(`/api/projects/${projectId}`, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('jwtToken')}` },
      params: { 'include-modality-metadata': "true", "include-task-type-metadata": "true" }
    }).then((projectDetailsRes) => {
      setError(false);
      const projectData = projectDetailsRes.data.project;
      setProjectModality(projectData['projectModality']);
      setProjectTaskType(projectData['projectTaskType']);
      setProjectLabels(projectData['projectLabels']);
    }).catch((error) => {
      setError(true)
      console.log(error);
    });

    // Fetch annotations chain
    api.get(`/api/projects/${projectId}/data-chunks/${dataChunkId}/annotation-chain`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
    }).then((res) => {
      setError(false);
      const { annotationChain } = res.data;
      if (annotationChain && annotationChain.length > 0) {
        setAnnotationChain(annotationChain);
        configureAnnotationInstance(annotationChain[0]);
      } else 
        setTaskCompleted(true);
      
      setInstanceLoading(false);
    }).catch((error) => {
      setError(true);
      console.log(error);
      setInstanceLoading(false);
    });

    return () => {};
  }, [projectId, dataChunkId]);

  useEffect(() => {
    const asyncInitializeLabelStudio = async () => {
      if (projectId && projectModality && projectTaskType && projectLabels && instanceParentDataset && instanceRelativePath && instanceId) {
        await initializeLabelStudio(
          projectModality,
          projectTaskType,
          projectLabels,
          [],
          dataChunkId,
          instanceId,
          instanceRelativePath,
          handleAnnotationSubmission
        );
        setPreviousInstanceId(instanceId);
        setInstanceLoading(false);
      }
    };

    asyncInitializeLabelStudio();
  }, [projectId, projectModality, projectTaskType, projectLabels, instanceId, instanceParentDataset, instanceRelativePath, submissionClicks]);

  const configureAnnotationInstance = (instance) => {
    setInstanceId(instance.id);
    setInstanceName(instance.name);
    setInstanceParentDataset(instance.parentDataset);
    setInstanceRelativePath(instance.relativePath);
  };

  const handleAnnotationSubmission = async (annotation, annotationTime, targetInstance, targetDataChunk) => {
    setInstanceLoading(true);
    setSubmissionClicks(submissionClicks + 1)
    const data = {
      'annotation_body': annotation,
      'annotation_time': annotationTime,
      'target_instance': targetInstance,
      'target_data_chunk': targetDataChunk
    };

    try {
      await api.post(`/api/annotations`, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json'
        }
      });
      await dequeueAnnotationChain(); // Wait for the queue update before proceeding
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (projectTaskType) {
      switch (projectTaskType['name']) {
        case "PolygonLabels":
          import("./AnnotDashPolygonLabels.css").then(() => setSelectedStyle("annot-dash-polygon-labels"));
          break;
        case "RectangleLabels":
          import("./AnnotDashRectangleLabels.css").then(() => setSelectedStyle("annot-dash-rectangle-labels"));
          break;
        case "ImageClassification":
          import("./AnnotDashImageClassification.css").then(() => setSelectedStyle("annot-dash-image-classification"));
          break;
        case "ImageCaptioning":
          import("./AnnotDashImageCaptioning.css").then(() => setSelectedStyle("annot-dash-image-captioning"));
          break;
        case "AudioCaptioning":
          import("./AnnotDashAudioCaptioning.css").then(() => setSelectedStyle("annot-dash-audio-captioning"));
          break;
        case "AudioClassification":
          import("./AnnotDashAudioClassification.css").then(() => setSelectedStyle("annot-dash-audio-classification"));
          break;
        case "SegmentsAudioClassification":
          import("./AnnotDashSegmentsAudioClassification.css").then(() => setSelectedStyle("annot-dash-segments-audio-classifiction"));
          break;
        case "SegmentsAudioCaptioning":
          import("./AnnotDashSegmentsAudioCaptioning.css").then(() => setSelectedStyle("annot-dash-segments-audio-captioning"));
          break;
        case "ValenceArousal":
          import("./AnnotDashValenceArousal.css").then(() => setSelectedStyle("annot-dash-valence-arousal"));
          break;
        case "AdversarialDetection":
          import("./AnnotDashAdversarialDetection.css").then(() => setSelectedStyle("annot-dash-adversarial-detection"));
          break;
        default:
          setSelectedStyle("");
          break;
      }
    }
  }, [projectTaskType]);

  return (
    <section className="dashboard annotation-dashboard">
      {error ? (
        <div className="message-container">
            <img src={ErrorIcon} className="message-icon" alt="Error icon" />
            <p className="message-text-main">An unexpected error occurred.</p> 
            <p className="message-text">Please try again later.</p>
        </div>
      ) : taskCompleted ? (
        <div className="task-completed-container">
          <img src={taskCompletedIcon} alt="task-completed-icon" className="task-completed-icon"/>
          <span className="task-completed-text">Annotation task completed!</span>
          <Link to="/annotator/dashboard/projects" className="return-dashboard-link">Return to Dashboard</Link>
        </div>
      ) : (
        <>
          <h2>{instanceName}</h2>
          <div className="label-studio-container">
            {instanceLoading && (
              <div className="loading-indicator">
                <div className="spinner"></div>
                <span>Loading...</span>
              </div>
            )}
            <div 
              id="label-studio" 
              style={{ display: instanceLoading ? 'none' : 'block' }}
              className={selectedStyle}
            ></div>
          </div>
        </>
      )}
    </section>
  );
}

export default AnnotationDashboard;
