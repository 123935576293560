import React, { useState, useEffect } from "react";
import api from '../../../axiosApi/api';
import FiltersModal from '../../Modals/FiltersModal/FiltersModal';
import UploadDatasetModal from '../../Modals/UploadDatasetModal/UploadDatasetModal';
import DatasetsUtilsBar from '../../UtilsBar/DatasetsUtilsBar/DatasetsUtilsBar';

import DatasetFolderIcon from '../../../Images/dataset-folder.png';
import naIcon from "../../../Images/naNew.png";
import ErrorIcon from "../../../Images/error-icon.png";

import "./DatasetsDashboard.css";
import './../Dashboard.css';


function DatasetsDashboard() {
  const [openDatasetUploadModal, setOpenDatasetUploadModal] = useState(false);
  const [data, setData] = useState([]);
  const [datasetsLoading, setDatasetsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchDatasets();
    return () => {};
  }, []);

  const fetchDatasets = async () => {
    try {
      const response = await api.get('/api/datasets', {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}
      });
      setError(false)
      setData(response.data.datasets);
    } catch (error) {
      setError(true);
      console.error("Error fetching datasets:", error);
    } finally {
      setDatasetsLoading(false);
    }
  };

  // Function to submit new dataset to database
  const uploadDataset = async (folderName, selectedInstances) => {
    try {
      const data = {
        'datasetName': folderName,
        'selectedInstances': selectedInstances
      };
      await api.post('/api/datasets', data, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        },
      });
      fetchDatasets();
    } catch (error) {
      console.error("Error uploading dataset:", error);
    }
  };

  return (
    <>
      <UploadDatasetModal open={openDatasetUploadModal} onClose={() => setOpenDatasetUploadModal(false)} uploadDataset={uploadDataset}/>
      <section className="dashboard datasets-dashboard">
        <h2>Available Datasets</h2>
        <DatasetsUtilsBar 
          filteredData={data}
          setFilteredData={setData}
          openDatasetUploadModal={() => setOpenDatasetUploadModal(true)}
        />
        <div className="dataset-cards-container"> 
          {error ? (
            <div className="message-container">
              <img src={ErrorIcon} className="message-icon" alt="Error icon" />
              <p className="message-text-main">An unexpected error occurred.</p> 
              <p className="message-text">Please try again later.</p>
            </div>
          ) : datasetsLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : data.length > 0 ? (
            <div className="dataset-cards-grid">
              {data.map((dataset) => (
                <div key={dataset["_id"]} className="dataset-card">
                  <div className="card-fields-group">
                    <span className="card-field card-title" title={dataset.name}>{dataset.name}</span>
                    <span className="card-field card-date" title={dataset.upload_date}>{dataset.upload_date}</span>
                  </div>
                  <div className="card-icon-container">
                    <img className="card-icon" src={DatasetFolderIcon} alt="Dataset Icon" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="message-container">
              <img src={naIcon} className="message-icon" alt="NA Icon"/>
              <span className="message-text">No available datasets.</span>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default DatasetsDashboard;
