import React, { useEffect, useState, useRef } from 'react';
import api from '../../../../../axiosApi/api';

import "./InstanceSelectionStage.css"
import RightArrowIcon from "../../../../../Images/right-arrow.png"
import DownArrowIcon from "../../../../../Images/down-arrow.png"


const InstanceSelectionStage = ({selectedDataset, selectedModality, selectedInstances, setSelectedInstances, selectedChunks, setSelectedChunks}) => {
  const [fetchedInstances, setFetchedInstances] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [expandedStates, setExpandedStates] = useState([]);
  const [instancesLoading, setInstancesLoading] = useState(true);
  const rangeInputRef = useRef(null);

  // Fetch instances of selected dataset
  useEffect(() => {
    // grouped=${true}: group instances by parent folder
    const datasetID = selectedDataset._id;
    api.get(`/api/datasets/${datasetID}/instances?grouped=${true}`, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
    })
    .then((res) => {
      setFetchedInstances(res.data.groupedInstances);
      
      // Initialize checkbox states
      const initialCheckboxStates = res.data.groupedInstances.map(group => (
        Array(group.instances.length).fill(false)
      ));
      setCheckboxStates(initialCheckboxStates);   
      setSelectedInstances([])     
      setInstancesLoading(false)
    })
    .catch((error) => {
      console.error("Error fetching data from the API:", error);
      setInstancesLoading(false)
    });
  }, []);

  // Update selectedInstances when checkboxes state is updated
  useEffect(() => {
    let totalCount = 0;
    let newSelectedInstances = []
    checkboxStates.forEach((group, groupIndex) => {
      group.forEach((checkbox, instanceIndex) => {
        if (checkbox) {
          totalCount++;
          const instance = fetchedInstances[groupIndex].instances[instanceIndex];
          newSelectedInstances = [...newSelectedInstances, instance]
        }
      });
    });
    setSelectedInstances(newSelectedInstances);
    (totalCount === 0) ?? setSelectedChunks(0);
  }, [checkboxStates]);

  // Update slider state when selectedChunks is uploaded
  useEffect(() => {  
    const progress = ( Math.min(selectedInstances.length, 100) > 0) ? (selectedChunks / Math.min(selectedInstances.length, 100)) * 100 : 0;
    rangeInputRef.current.style.background = `linear-gradient(to right, #FFA21F ${progress}%, #DAFBF7 ${progress}%)`;
    
  }, [selectedChunks, selectedInstances]);

  // Handle check of single checkbox
  const handleSingleCheckboxUpdate = (groupIndex, instanceIndex) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[groupIndex][instanceIndex] = !newCheckboxStates[groupIndex][instanceIndex];
    setCheckboxStates(newCheckboxStates);

    // Update selectAllChecked if all checkboxes in all groups are checked
    const allChecked = newCheckboxStates.every(group => group.every(state => state));
    setSelectAllChecked(allChecked);
  };
  
  const isCheckboxEnabled = (groupIndex, instanceIndex) => {
    return isFileTypeAllowed(fetchedInstances[groupIndex].instances[instanceIndex].fileType, groupIndex, instanceIndex);
  };

  // Handle check of folder checkbox
  const handleFolderCheckboxUpdate = (groupIndex) => {
    const newCheckboxStates = checkboxStates.map((group, gIndex) => (
      gIndex === groupIndex ? group.map((state, instanceIndex) => (
        isCheckboxEnabled(groupIndex, instanceIndex) ? group.every(state => !state) : state
      )) : group
    ));
    setCheckboxStates(newCheckboxStates);

    // Update selectAllChecked if all checkboxes in all groups are checked
    const allChecked = newCheckboxStates.every(group => group.every(state => state));
    setSelectAllChecked(allChecked);
  };  
  
  // Handle selectAll checkbox
  const handleSelectAllCheckboxUpdate = () => {
    const newCheckboxStates = checkboxStates.map((group, groupIndex) => (
      group.map((state, instanceIndex) => (
        isCheckboxEnabled(groupIndex, instanceIndex) ? group.every(state => !state) : state
      ))
    ));
  
    setCheckboxStates(newCheckboxStates);
  
    // Update selectAllChecked if all checkboxes in all groups are checked
    const allChecked = newCheckboxStates.every(group => group.every(state => state));
    setSelectAllChecked(allChecked);
  };

  // Method to disable a file checkbox if file type is not acceptable
  const isFileTypeAllowed = (fileType, groupIndex, fileIndex) => {
    const allowedFileTypes = selectedModality.fileTypes.split(',').map(type => type.trim().toLowerCase());
    const fileIsAcceptable = allowedFileTypes.includes(fileType.toLowerCase());

    return fileIsAcceptable
  };
  
  // Method to expand file divs belonging to a folder
  const toggleExpanded = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  return (
    <div className="instance-selection-container">
      <h5>Select Instances</h5>
      <div className="instances-section">
        <div className="instances-section-header">
          <h6>{selectedDataset.name}</h6>
          <div className="checkbox-wrapper">
            <label className="checkbox-item">
              <span className="checkbox-label">Select all</span>
              <input
                type="checkbox"
                value={0}
                checked={selectAllChecked}
                onChange={handleSelectAllCheckboxUpdate}
              />
              <span className="check-mark"></span>
            </label>
          </div>
        </div>
        <div className="instances-section-body">
          {instancesLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ):(
            <>
              <div className="instances-fields-header">
                <div className="fields-section">
                  <span className="header-field">Name</span>
                  <span className="header-field">File Type</span>
                  <span className="header-field">File Size</span>
                </div>
                <div className="empty-section">
                </div>  
              </div>
              <div className="instances-list">
                {fetchedInstances.map((group, groupIndex) => (
                  <div className="folder-sublist" key={groupIndex}>
                    <div className="folder-sublist-folder-item">
                      <div className="item-fields-section">
                        <span className="item-field">{group.group}</span>
                      </div>
                      <div className='item-utils-section'>
                        <label key={groupIndex} className="checkbox-item">
                          <span className="checkbox-label"></span>
                          <input
                            type="checkbox"
                            value={0}
                            checked={checkboxStates[groupIndex].every(state => state)}
                            onChange={() => handleFolderCheckboxUpdate(groupIndex)}
                          />
                          <span className="check-mark"></span>
                        </label>
                        <div className="expand-btn" onClick={() => toggleExpanded(groupIndex)}>
                          <img src={expandedStates[groupIndex] ? DownArrowIcon : RightArrowIcon}/>
                        </div>
                      </div>
                    </div>
                    {expandedStates[groupIndex] && (
                      group.instances.map((instance, instanceIndex) => (
                        <div className="folder-sublist-file-item" key={instance._id}>
                          <div className="item-fields-section">
                            <span className="item-field">{instance.name}</span>
                            <span className="item-field">{instance.fileType}</span>
                            <span className="item-field">{instance.fileSize}</span>
                          </div>
                          <div className="item-utils-section">
                            <label key={instanceIndex} className="checkbox-item">
                              <span className="checkbox-label"></span>
                              <input
                                type="checkbox"
                                value={checkboxStates[groupIndex][instanceIndex]}
                                checked={checkboxStates[groupIndex][instanceIndex]}
                                disabled={!isFileTypeAllowed(instance.fileType, groupIndex, instanceIndex)}
                                onChange={() => handleSingleCheckboxUpdate(groupIndex, instanceIndex)}
                              />
                              <span className="check-mark"></span>
                            </label>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
      </div>
      <div className="data-split-container">
        <h6>Split instances into chunks</h6>
        <div className="splits-selection-section">
          <span>Select number of data chunks:</span>          
          <div className="range-slider">
            <input
              ref={rangeInputRef}
              type="range"
              min="1"
              max = {Math.min(selectedInstances.length, "100")}
              value={selectedChunks}
              id="range"
              onChange={(e) => setSelectedChunks(parseInt(e.target.value))}
              disabled={selectedInstances.length===0}
            />
            <input
              type="number"
              value={(selectedInstances.length > 0) ? selectedChunks : 0}
              min="1"
              max={Math.min(selectedInstances.length, "100")}
              step="1"
              onChange={(e) => setSelectedChunks(parseInt(e.target.value))}
              onBlur={(e) => {
                const value = parseInt(e.target.value);
                if (value > Math.min(selectedInstances.length, 100)) {
                  setSelectedChunks(Math.min(selectedInstances.length, 100));
                }
              }}
              disabled={selectedInstances.length===0}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default InstanceSelectionStage;