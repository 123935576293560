import React, { useState, useEffect } from "react";
import api from '../../../../../axiosApi/api';
import './DatasetSelectionStage.css';
import DatasetFolderIcon from '../../../../../Images/dataset-folder.png';

const DatasetSelectionStage = ({ selectedDataset, setSelectedDataset }) => {
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [availableDatasets, setAvailableDatasets] = useState([]);

  useEffect(() => {
    const fetchDatasets = async () => {
      setLoadingDatasets(true);
      try {
        const response = await api.get('/api/datasets', {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }
        });
        setAvailableDatasets(response.data.datasets);
      } catch (error) {
        console.error("Error fetching datasets:", error);
      } finally {
        setLoadingDatasets(false);
      }
    };

    fetchDatasets();
  }, []);

  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
  };

  return (
    <div className="dataset-selection-container">
      <h5>Select Dataset</h5>
      <div className="datasets-section">
        <h6>Available datasets</h6>
        <div className="dataset-grid-container">
          {loadingDatasets ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <div className="dataset-cards-grid">
              {
                availableDatasets.map((dataset) => (
                  <div
                    key={dataset._id}
                    className={`dataset-card ${selectedDataset && dataset._id === selectedDataset._id ? 'selected' : ''}`}
                    onClick={() => handleDatasetSelection(dataset)}
                  >
                    <div className="card-fields-group">
                      <span className="card-field card-title" title={dataset.name}>{dataset.name}</span>
                      <span className="card-field card-date" title={dataset.uploadDate}>{dataset.uploadDate}</span>
                    </div>
                    <div className="card-icon-container">
                      <img className="card-icon" src={DatasetFolderIcon} alt={`Dataset ${dataset.name}`} />
                    </div>
                  </div>
                ))
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatasetSelectionStage;
