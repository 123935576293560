import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import SignIn from './pages/Auth/SignIn';
import Register from './pages/Auth/Register';
import Header from './components/Header/Header';
import AdminDashboardPage from './pages/Admin/AdminDashboardPage';
import AnnotatorDashboardPage from './pages/Annotator/AnnotatorDashboardPage';
import RequireAdmin from "./components/RequireAdminComponent/RequireAdmin";
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import AboutPage from './pages/AboutPage/AboutPage';


function App() {
  const location = useLocation();

  useEffect(() => {
    document.title = "Annotation Pipeline";
    return () => {
      document.title = "Annotation Pipeline";
    };
  }, []);

  const shouldRenderHeader = !['/signin', '/'].includes(location.pathname);

  const renderHeader = () => {
    if (shouldRenderHeader) {
      return <Header />;
    }
    return null;
  };

  return (
    <div className="app-container">
      {renderHeader()}
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/signin" element={<SignIn />} />

        <Route
          path="/admin/dashboard/*"
          element={<RequireAdmin><AdminDashboardPage/></RequireAdmin>
          }
        />
        <Route
          path="/annotator/dashboard/*"
          element={<AnnotatorDashboardPage />}
        />
        <Route
          path="/annotator/about/*"
          element={<AboutPage />}
        />
        <Route
          path="/admin/about/*"
          element={<AboutPage />}
        />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
