function AboutUs() {
    return (
        <>
            <h3>About Us</h3>
            <p>This pipeline was developed by the Multimedia Analysis Group of the Computational Intelligence Lab (MagCIL) at the Institute of Informatics and Telecommunications, National Center for Scientific Research "Demokritos". At MagCIL, we conduct research in multimodal machine learning, including multimodal speech analytics, music information analysis, video classification, speech recognition, speech emotion recognition, video summarization, and image retrieval.</p>
            <p>For more information about our lab, visit the following links:</p>
            <ul>
                <li><a href="https://labs-repos.iit.demokritos.gr/MagCIL/" target="_blank" rel="noopener noreferrer">MagCIL website</a></li>
                <li><a href="https://github.com/magcil" target="_blank" rel="noopener noreferrer">MagCIL on GitHub</a></li>
            </ul>
            
        </>
    )
}

export default AboutUs;