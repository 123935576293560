import React, { useState, useEffect } from "react";
import filterIcon from "../../../Images/filter-dark.png";
import uploadIcon from "../../../Images/upload-icon.png";
import sortIcon from "../../../Images/sort-dark.png";
import sortDownIcon from "../../../Images/sort-down-dark.png";
import "../UtilsBar.css";

function DatasetsUtilsBar({ filteredData, setFilteredData, openFiltersModal, openDatasetUploadModal }) {
  const [selectedSortOption, setSelectedSortOption] = useState('Sort By');

  useEffect(() => {
    const sortData = () => {
      let sortedCards = [...filteredData];
      if (selectedSortOption === 'Alphabetically') {
        sortedCards.sort((a, b) => a.name.localeCompare(b.name));
      } else if (selectedSortOption === 'Date') {
        sortedCards.sort((a, b) => {
          const dateA = new Date(a.upload_date);
          const dateB = new Date(b.upload_date);
          return dateA - dateB;
        });
      }
      setFilteredData(sortedCards);
    };

    sortData();
  }, [selectedSortOption]);

  return (
    <div className="utils-container">
      <div className='utils-section' >
        {/* <div className="dropdown">
          <button 
            className="util-btn dropdown-toggle"
            type="button"
            id="SortByDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p className="sort-btn-text">{selectedSortOption}</p>
            <img className="main-sort-icon" src={sortDownIcon} alt="SortDownIcon"/>
            <img className="second-sort-icon" src={sortIcon} alt="SortIcon"/>
          </button>
          <div className="dropdown-menu" aria-labelledby="SortByDropdown" >
            <button
              className="dropdown-item"
              type="button"
              onClick={() => setSelectedSortOption('Alphabetically')}
            >
              Alphabetically
            </button>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => setSelectedSortOption('Date')}
            >
              Date
            </button>
          </div>
        </div>

        <button className="util-btn" onClick={() => openFiltersModal()}>
          <img src={filterIcon} className="filter-btn-icon" alt="FilterIcon" />
        </button>
        <input type="text" placeholder="Search.." className="util-searchbar" /> */}

      </div>
      <button className="upload-dataset-btn" onClick={() => openDatasetUploadModal()}>
        <p className="upload-btn-text">Upload</p>
        <img src={uploadIcon} className="upload-btn-icon" alt="UploadIcon" />
      </button>
    </div>
  );
}

export default DatasetsUtilsBar;
